import { useAuth0 } from '@auth0/auth0-react';
import { useJWTStore } from 'crunch-components';

const useLogout = () => {
    const { logout: auth0Logout } = useAuth0();
	const { setJWT } = useJWTStore();

    return async () => {
		await auth0Logout({ logoutParams: { returnTo: window.location.origin } });
		setJWT(null);
	};
}

export default useLogout;
