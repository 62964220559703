import PredictionEvaluationDashboard from '../components/PredictionEvaluationDashboard';
import PredictionEvaluationHeader from '../components/PredictionEvaluationHeader';

const PredictionEvaluationView = () => {
	return (
		<>
			<PredictionEvaluationHeader />
			<div className="mt-10">
				<PredictionEvaluationDashboard />
			</div>
		</>
	);
};

export default PredictionEvaluationView;
