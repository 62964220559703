import {
	CREATE_BUSINESS_RULE,
	GET_BUSINESS_RULE,
	GET_BUSINESS_RULES_ACTION_TYPES,
	GET_BUSINESS_RULES_GLOBAL_ACTIONS,
	GET_BUSINESS_RULES_GLOBAL_FIELDS,
	GET_BUSINESS_RULES_REPEATING_FIELDS,
	UPDATE_BUSINESS_RULE,
} from 'api/business-rules';

import Condition from 'components/Condition/Condition';
import { filtersToFields } from 'components/Condition/Condition.utils';

import StratosCheckbox from 'components/StratosCheckbox/StratosCheckbox';
import {
	CheckmarkIcon,
	CircularProgress,
	cn,
	Conditional,
	Dropdown,
	GavelIcon,
	IconButton,
	InfoIcon,
	InputWithLabel,
	RepeatIcon,
	SingleTrack,
	Text,
	TextInput,
	Tooltip,
	useModal,
	useToast,
} from 'crunch-components';
import { range } from 'crunch-utils';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import { LIST_BUSINESS_RULES_QUERY_KEY } from 'hooks/queries/useBusinessRulesQuery';
import PropTypes from 'prop-types';
import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import ReactSlider from 'react-slider';

const mdIncrements = [
	0, 0.05, 0.1, 0.15, 0.2, 0.25, 0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65,
	0.7, 0.75, 0.8, 0.85, 0.9, 0.95,
];

const labelClassName = 'w-60 mt-2.5';

// these props make this component a bit messy
// but this bussiness logic should have been inside BusinessRuleCreateEditModalBase.tsx
const BusinessRulesCreateEdit = ({
	formRef,
	setIsLoading,
}) => {
	const navigate = useNavigate();
	const { close: closeModal } = useModal();
	const businessRuleId = new URLSearchParams(useLocation().search).get(
		'business-rule-id',
	);
	const isEditMode = !!businessRuleId;
	const [possibleDiscounts, setPossibleDiscounts] = useState([
		...mdIncrements,
	]);
	const [applyRule, setApplyRule] = useState(false);

	const {
		formState,
		setValue,
		control,
		handleSubmit,
		setError,
		clearErrors,
		watch
	} = useForm({
		defaultValues: {
			title: '',
			action_type: null,
			active: false,
			custom_fixed_action: {
				fixed_discount: 0.0,
			},
			custom_minmax_action: {
				min_discount: null,
				max_discount: null,
			},
			custom_possible_action: {
				markdowns: [],
			},
			custom_average_action: {
				average_discount: 0.5,
			},
			custom_distribution_action: {
				limiter: null,
				distribution: '',
				discount: null,
				type: null,
				field: null,
			},
			custom_min_change_action: {
				min_change_discount: 0.0,
			},
			custom_max_increase_action: {
				max_increase_discount: 0.0,
			},
			global_action_id: null,
			conditions: [],
		},
	});
	const queryClient = useQueryClient();
	const toast = useToast();

	// TODO: Rework setPossibleDiscounts to use SetValue instead
	const { channel } = useChannelQuery(
		['business-rule', businessRuleId],
		() => GET_BUSINESS_RULE(businessRuleId),
		{
			enabled: isEditMode,
			onError: async ({ response }) => {
				if (response?.status === 404) {
					navigate('strategy/business-rules');
				}
			},
			retry: false,
			// In the form global business rules are added as distinct types.
			// We need to mock that situation in data as well so the initial form state is correct.
			select: (br) => {
				if (br.action_type === 'Global') {
					br.action_type = br.global_action.description;
				}
				return br;
			},
			onSuccess: (data) => {
				Object.entries(data ?? {}).forEach(([key, val]) => {
					if (key === 'conditions') {
						setValue('conditions', val);
						return
					}

					if (key === 'custom_possible_action') {
						setPossibleDiscounts(val?.markdowns || []);
						return
					}

					if (key === 'custom_distribution_action') {
						setValue(key, {
							...val,
							distribution: val?.distribution * 100 || '',
						});
						setApplyRule(val?.field !== null);
						return
					}

					setValue(key, val);
				});
			}
		},
	);


	const { data: actionTypes = [], isFetching: isActionTypesFetching } =
		useChannelQuery(
			['business-rules-action-types'],
			GET_BUSINESS_RULES_ACTION_TYPES,
			{
				staleTime: 5 * 60 * 1000,
			},
		);

	const { data: globalActions, isFetching: isGlobalActionsFetching } =
		useChannelQuery(
			['business-rules-globalactions'],
			GET_BUSINESS_RULES_GLOBAL_ACTIONS,
			{
				staleTime: 5 * 60 * 1000,
			},
		);

	const { data: rawFields } = useChannelQuery(
		['business-rules-fields'],
		GET_BUSINESS_RULES_GLOBAL_FIELDS,
		{
			staleTime: 5 * 60 * 1000,
		},
	);

	const { data: repeatingFields } = useChannelQuery(
		['business-rules-repeating-fields'],
		GET_BUSINESS_RULES_REPEATING_FIELDS,
		{
			staleTime: 5 * 60 * 1000,
		},
	);

	const fields = useMemo(() => filtersToFields(rawFields), [rawFields]);

	const {
		mutate: createBusinessRule,
	} = useMutation(CREATE_BUSINESS_RULE, {
		onMutate: async (payload) => {
			// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
			setIsLoading(true);
			const queryKey = [channel, ...LIST_BUSINESS_RULES_QUERY_KEY];
			await queryClient.cancelQueries(queryKey);

			// Snapshot the previous value
			const previousBusinessRules = queryClient.getQueryData(queryKey);

			if (previousBusinessRules) {
				// Optimistically update to the new value
				queryClient.setQueryData(queryKey, (old) => {
					return {
						...old,
						items: [
							...(old.items || []),
							{
								...payload,
								priority: (old.items?.[0]?.priority || 0) + 1,
							},
						],
						total: old.total + 1,
					};
				});
			}

			// Return a context object with the snapshotted value
			return { previousBusinessRules };
		},
		// If the mutation fails, use the context returned from onMutate to roll back
		onError: (err, payload, context) => {
			queryClient.setQueryData(
				[channel, ...LIST_BUSINESS_RULES_QUERY_KEY],
				context.previousBusinessRules,
			);

			toast.show('Failed to update business rule', { type: 'error' });
		},
		onSuccess: () => {
			// maybe also show a toast
			toast.show('Business rule created', { type: 'success' });
			closeModal();
		},
		// Always refetch after error or success:
		onSettled: () => {
			queryClient.invalidateQueries([
				channel,
				...LIST_BUSINESS_RULES_QUERY_KEY,
			]);
			setIsLoading(false);
		},
	});

	const { mutate: editBusinessRule } = useMutation(
		(payload) => UPDATE_BUSINESS_RULE(payload.id, payload),
		{
			onMutate: async (payload) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				setIsLoading(true);
				const queryKey = [channel, ...LIST_BUSINESS_RULES_QUERY_KEY];
				await queryClient.cancelQueries(queryKey);

				// Snapshot the previous value
				const previousBusinessRules =
					queryClient.getQueryData(queryKey);

				if (previousBusinessRules) {
					// Optimistically update to the new value
					queryClient.setQueryData(
						[channel, ...LIST_BUSINESS_RULES_QUERY_KEY],
						(old) => {
							if (!old) return old;

							return {
								...old,
								items: (old?.items || []).map((item) => {
									if (item.id === payload.id) {
										return {
											...item,
											...payload,
										};
									}

									return item;
								}),
							};
						},
					);
				}

				// Return a context object with the snapshotted value
				return { previousBusinessRules };
			},
			// If the mutation fails, use the context returned from onMutate to roll back
			onError: (err, payload, context) => {
				queryClient.setQueryData(
					[channel, ...LIST_BUSINESS_RULES_QUERY_KEY],
					context.previousBusinessRules,
				);
				toast.show('Business rule failed to update', { type: 'error' });
			},
			onSuccess: (data) => {
				toast.show('Business rule has been updated');
				closeModal();
			},
			// Always refetch after error or success:

			onSettled: () => {
				queryClient.invalidateQueries([
					channel,
					...LIST_BUSINESS_RULES_QUERY_KEY,
				]);
				setIsLoading(false);
			},
		},
	);

	const watchType = watch('action_type');

	const onSubmit = async ({
		custom_fixed_action_id: _customFixedActionId,
		custom_minmax_action_id: _customMinMaxActionId,
		custom_possible_action_id: _customPossibleActionId,
		custom_average_action_id: _customAverageActionId,
		custom_distribution_action_id: _customDistributionActionId,
		conditions,
		...d
	}) => {
		clearErrors([
			'custom_minmax_action.min_discount',
			'custom_minmax_action.max_discount',
			'custom_fixed_action.fixed_discount',
			'custom_possible_action.markdowns',
			'custom_average_action.average_discount',
			'custom_average_action.custom_distribution_action',
			'custom_min_change_action.min_change_discount',
			'custom_max_increase_action.max_increase_discount',
		]);

		const errors = [];

		if (
			d?.action_type === 'Custom_minmax' ||
			d?.action_type === 'Custom_fixed' ||
			d?.action_type === 'Custom_min_change' ||
			d?.action_type === 'Custom_max_increase'
		) {
			[
				[
					'custom_minmax_action.min_discount',
					d?.custom_minmax_action?.min_discount,
				],
				[
					'custom_minmax_action.max_discount',
					d?.custom_minmax_action?.max_discount,
				],
				[
					'custom_fixed_action.fixed_discount',
					d?.custom_fixed_action?.fixed_discount,
				],
				[
					'custom_min_change_action.min_change_discount',
					d?.custom_min_change_action?.min_change_discount,
				],
				[
					'custom_max_increase_action.max_increase_discount',
					d?.custom_max_increase_action?.max_increase_discount,
				],
			].forEach(([key, value]) => {
				if (value === undefined || value === null || value === '') {
					return null;
				}

				if (Number.isNaN(parseFloat(value))) {
					return errors.push([
						key,
						{
							type: 'validate',
							message: 'Value must be a valid number',
						},
					]);
				}

				if (value > 0.95 || value < 0) {
					return errors.push([
						key,
						{
							type: 'validate',
							message: 'Value must be between 0 - 95 %',
						},
					]);
				}

				return null;
			});
		}

		if (d?.action_type === 'Custom_minmax') {
			if (
				Number.isNaN(
					parseFloat(d?.custom_minmax_action?.min_discount),
				) &&
				Number.isNaN(parseFloat(d?.custom_minmax_action?.max_discount))
			) {
				errors.push(
					[
						'custom_minmax_action.min_discount',
						{
							type: 'validate',
							message: 'Either min./max. or both need to be set',
						},
					],
					[
						'custom_minmax_action.max_discount',
						{
							type: 'validate',
							message: 'Either min./max. or both need to be set',
						},
					],
				);
			}
		}

		if (d?.action_type === 'Custom_possible' && !possibleDiscounts.length) {
			errors.push([
				'custom_possible_action.markdowns',
				{
					type: 'validate',
					message: 'At least 1 markdown must be selected',
				},
			]);
		}

		if (d?.action_type === 'Custom_distribution') {
			const distribution = parseInt(
				d?.custom_distribution_action?.distribution,
				10,
			);

			if (
				Number.isNaN(distribution) ||
				distribution < 0 ||
				distribution > 100
			) {
				errors.push([
					'custom_distribution_action.distribution',
					{
						type: 'validate',
						message: 'Value must be between 0 - 100 %',
					},
				]);
			}
		}

		if (
			globalActions.items.some(
				(globalAction) => d?.action_type === globalAction.description,
			) &&
			d.global_action_id == null
		) {
			errors.push([
				'action_type',
				{
					type: 'global',
					message: 'No global action was selected.',
				},
			]);
		}

		if (errors.length > 0) {
			errors.forEach(([key, err]) => setError(key, err));

			// bail out of submission when custom validation errors were triggered
			return null;
		}

		const payload = {
			...d,
			// Global actions are selectable like other action types in the dropdown but the real action_type for these should be 'Global'
			action_type: globalActions.items.some(
				(globalAction) => globalAction.description === d.action_type,
			)
				? 'Global'
				: d.action_type,
			custom_possible_action: {
				markdowns: possibleDiscounts.sort(),
			},
			custom_distribution_action: {
				...d?.custom_distribution_action,
				distribution:
					parseInt(d?.custom_distribution_action?.distribution, 10) /
					100,
				field: applyRule ? d?.custom_distribution_action?.field : null,
			},
			conditions
		};

		const finalPayload = {
			title: payload.title,
			tag_id: payload.tag_id,
			action_type: payload.action_type,
			conditions: payload.conditions,
		};

		if (payload.action_type === 'Global')
			finalPayload.global_action_id = payload.global_action_id;
		if (payload.action_type === 'Custom_min_change')
			finalPayload.custom_min_change_action =
				payload.custom_min_change_action;
		if (payload.action_type === 'Custom_max_increase')
			finalPayload.custom_max_increase_action =
				payload.custom_max_increase_action;
		if (payload.action_type === 'Custom_fixed')
			finalPayload.custom_fixed_action = payload.custom_fixed_action;
		if (payload.action_type === 'Custom_minmax')
			finalPayload.custom_minmax_action = payload.custom_minmax_action;
		if (payload.action_type === 'Custom_possible')
			finalPayload.custom_possible_action =
				payload.custom_possible_action;
		if (payload.action_type === 'Custom_average')
			finalPayload.custom_average_action = payload.custom_average_action;
		if (payload.action_type === 'Custom_distribution')
			finalPayload.custom_distribution_action =
				payload.custom_distribution_action;

		if (payload.id) finalPayload.id = payload.id;
		if (payload.priority) finalPayload.priority = payload.priority;

		isEditMode
			? editBusinessRule(finalPayload)
			: createBusinessRule(finalPayload);
	};

	const handleActiveMarkdownChange = (md) => {
		if (possibleDiscounts.includes(md)) {
			setPossibleDiscounts((pD) => pD.filter((aMd) => aMd !== md));
		} else {
			setPossibleDiscounts((pD) => [...pD, md]);
		}
	};

	return (
		<>
			<form
				className="flex flex-col gap-4"
				ref={formRef}
				onSubmit={handleSubmit(onSubmit)}
			>
				<InputWithLabel
					labelPosition="label-above-input"
					label="Rule name"
					htmlFor="title"
					labelClassName={labelClassName}
				>
					<Controller
						name="title"
						control={control}
						rules={{ required: 'Required field' }}
						render={({ field }) => (
							<TextInput
								id="title"
								className="w-full sm:w-64"
								value={field.value}
								onChange={(val) => field.onChange(val)}
								error={formState?.errors?.title?.message}
							/>
						)}
					/>
				</InputWithLabel>
				<Condition
					setValue={setValue}
					fields={fields}
					errors={formState.errors}
					control={control}
					watch={watch}
				/>
				<Conditional
					classNameLabel="bg-mi-velvet-lilac-lighter text-mi-velvet-lilac rounded-none rounded-br"
					classNameLeftBorder="border-mi-velvet-lilac-lighter"
					label={
						<span className="flex items-center gap-2">
							<GavelIcon className="h-4 w-4" />
							BUSINESS RULE
						</span>
					}
				>
					<div className="mb-4 flex flex-col gap-4">
						<InputWithLabel
							labelPosition="label-above-input"
							label="Type of rule"
							labelClassName={labelClassName}
							htmlFor="action_type"
						>
							{isActionTypesFetching ||
								isGlobalActionsFetching ? (
								<CircularProgress />
							) : (
								<>
									<Controller
										name="action_type"
										control={control}
										rules={{ required: 'Required field' }}
										render={({ field }) => (
											<Dropdown
												placeholder="Choose"
												className="w-80"
												dropUpDown="up"
												contentClassName="max-h-[256px]"
												value={field.value}
												onChange={(val) => {
													field.onChange(val);

													// Only if selected option is a global action (special case)
													const selectedGlobalAction =
														globalActions.items.find(
															(globalAction) =>
																globalAction.description ===
																val,
														);
													if (selectedGlobalAction) {
														setValue(
															'global_action_id',
															selectedGlobalAction.id,
														);
													}
												}}
												options={[
													{
														value: 'Custom_minmax',
														label: 'Min/max. discount',
													},
													{
														value: 'Custom_fixed',
														label: 'Fixed discount',
													},
													{
														value: 'Custom_min_change',
														label: 'Minimal change discount',
													},
													{
														value: 'Custom_max_increase',
														label: 'Maximal increase discount',
													},
													{
														value: 'Custom_possible',
														label: 'Possible discounts',
													},
													{
														value: 'Custom_average',
														label: 'Average discount',
													},
													{
														value: 'Custom_distribution',
														label: 'Discount distribution',
													},
													...globalActions.items.map(
														({
															description,
															id,
														}) => ({
															value: description,
															label: description,
														}),
													),
												].filter(
													({ value }) =>
														actionTypes.includes(
															value,
														) ||
														globalActions.items.some(
															({ description }) =>
																value ===
																description,
														),
												)}
												error={
													formState?.errors
														?.action_type?.message
												}
											/>
										)}
									/>
								</>
							)}
						</InputWithLabel>

						{!isGlobalActionsFetching &&
							(globalActions?.items ?? [])
								.filter(
									({ description }) =>
										watchType === description,
								)
								.map(({ description }) => {
									return (
										<div className="flex items-center gap-2">
											<CheckmarkIcon className="h-5 w-5 rounded-full bg-mi-velvet-lilac stroke-2 p-1 text-white" />
											<Text type="secondary">
												Enabled rule: '{description}'
											</Text>
										</div>
									);
								})}
						{watchType === 'Custom_minmax' && (
							<div className="w-full">
								<div className="flex gap-10">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Minimum discount"
										htmlFor="custom_minmax_action.min_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_minmax_action.min_discount"
											control={control}
											render={({ field }) => (
												<div className="flex items-center space-x-2">
													<Dropdown
														id="custom_minmax_action.min_discount"
														placeholder="Choose"
														className="w-full md:w-48 lg:w-64"
														value={field.value}
														onChange={(val) =>
															field.onChange(val)
														}
														options={range(
															0,
															95,
															5,
														).map((v) => ({
															value: v / 100,
															label: `${v}%`,
														}))}
														error={
															formState?.errors
																?.custom_minmax_action
																?.min_discount
																?.message
														}
													/>
													<div className="inline-flex">
														<IconButton
															icon={RepeatIcon}
															tooltip="Reset"
															className="h-5"
															onClick={() =>
																field.onChange(
																	null,
																)
															}
														/>
													</div>
												</div>
											)}
										/>
									</InputWithLabel>
									<InputWithLabel
										labelPosition="label-above-input"
										label="Maximum discount"
										htmlFor="custom_minmax_action.max_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_minmax_action.max_discount"
											control={control}
											render={({ field }) => (
												<div className="flex items-center gap-2">
													<Dropdown
														id="custom_minmax_action.max_discount"
														placeholder="Choose"
														className="w-full md:w-48 lg:w-64"
														value={field.value}
														onChange={(val) =>
															field.onChange(val)
														}
														options={range(
															0,
															95,
															5,
														).map((v) => ({
															value: v / 100,
															label: `${v}%`,
														}))}
														error={
															formState?.errors
																?.custom_minmax_action
																?.max_discount
																?.message
														}
													/>
													<div className="inline-flex">
														<IconButton
															icon={RepeatIcon}
															tooltip="Reset"
															className="h-5"
															onClick={() =>
																field.onChange(
																	null,
																)
															}
														/>
													</div>
												</div>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_fixed' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Fixed discount"
										htmlFor="custom_fixed_action.fixed_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_fixed_action.fixed_discount"
											control={control}
											rules={{
												required: 'Required field',
											}}
											render={({ field }) => (
												<Dropdown
													id="custom_fixed_action.fixed_discount"
													placeholder="Choose"
													className="w-full md:w-48 lg:w-64"
													value={field.value}
													dropUpDown={'up'}
													onChange={(val) =>
														field.onChange(val)
													}
													options={range(
														0,
														95,
														5,
													).map((v) => ({
														value: v / 100,
														label: `${v}%`,
													}))}
													error={
														formState?.errors
															?.custom_fixed_action
															?.fixed_discount
															?.message
													}
												/>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_min_change' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Minimal change in discount"
										htmlFor="custom_min_change_action.min_change_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_min_change_action.min_change_discount"
											control={control}
											rules={{
												required: 'Required field',
											}}
											render={({ field }) => (
												<Dropdown
													id="custom_min_change_action.min_change_discount"
													placeholder="Choose"
													className="w-full md:w-48 lg:w-64"
													value={field.value}
													onChange={(val) =>
														field.onChange(val)
													}
													options={range(
														0,
														95,
														5,
													).map((v) => ({
														value: v / 100,
														label: `${v}%`,
													}))}
													error={
														formState?.errors
															?.custom_min_change_action
															?.min_change_discount
															?.message
													}
												/>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_max_increase' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										label="Maximal increase in discount"
										htmlFor="custom_max_increase_action.max_increase_discount"
										className="justify-between"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_max_increase_action.max_increase_discount"
											control={control}
											rules={{
												required: 'Required field',
											}}
											render={({ field }) => (
												<Dropdown
													id="custom_max_increase_action.max_increase_discount"
													placeholder="Choose"
													className="w-full md:w-48 lg:w-64"
													value={field.value}
													onChange={(val) =>
														field.onChange(val)
													}
													options={range(
														0,
														95,
														5,
													).map((v) => ({
														value: v / 100,
														label: `${v}%`,
													}))}
													error={
														formState?.errors
															?.custom_max_increase_action
															?.max_increase_discount
															?.message
													}
												/>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_possible' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_possible_action.markdowns"
										label="Markdowns"
										id="custom_possible_action.markdowns"
										labelClassName={labelClassName}
									>
										<div className="grid grid-cols-5 gap-x-8 gap-y-1">
											{mdIncrements.map((increment) => (
												<StratosCheckbox
													variant="outline"
													key={increment}
													checked={possibleDiscounts.includes(
														increment,
													)}
													onChange={() => {
														clearErrors(
															'custom_possible_action.markdowns',
														);
														handleActiveMarkdownChange(
															increment,
														);
													}}
													label={`${Math.round(increment * 100)}%`}
												/>
											))}
										</div>
									</InputWithLabel>
									{formState?.errors?.custom_possible_action
										?.markdowns?.message && (
											<div className="mt-2 w-full text-right text-xs text-ca-red">
												{
													formState?.errors
														?.custom_possible_action
														?.markdowns?.message
												}
											</div>
										)}
								</div>
							</div>
						)}
						{watchType === 'Custom_average' && (
							<div className="w-full">
								<div className="inline-block">
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_average_action.average_discount"
										label="Discount"
										id="custom_average_action.average_discount"
										labelClassName={labelClassName}
									>
										<Controller
											name="custom_average_action.average_discount"
											control={control}
											rules={{
												required: 'Required field',
											}}
											render={({ field }) => (
												<div className="relative flex flex-wrap items-center justify-center gap-2">
													<span className="text-xs text-mi-neutral-dark">
														0%
													</span>
													<ReactSlider
														className="mb-8 mt-4 w-64"
														min={0}
														max={100}
														value={
															field.value * 100
														}
														renderTrack={(
															props,
															state,
														) =>
															SingleTrack(
																{
																	...props,
																	className:
																		cn(
																			state.index ===
																				0
																				? 'bg-mi-velvet-lilac/75'
																				: 'bg-mi-velvet-lilac-light/60',
																		),
																},
																state,
															)
														}
														renderThumb={(
															{ key, ...props },
															state,
														) => (
															<div key={key}>
																<div
																	{...props}
																	className="h-4 w-4 cursor-grab rounded-full border border-mi-velvet-lilac bg-mi-velvet-lilac-lighter focus-visible:outline-none"
																/>
																{state.valueNow >
																	0 &&
																	state.valueNow <
																	100 && (
																		<span
																			className="top-5 text-xs text-mi-neutral-dark"
																			// eslint-disable-next-line react/prop-types
																			style={
																				props.style
																			}
																		>
																			{
																				state.valueNow
																			}
																			%
																		</span>
																	)}
															</div>
														)}
														onChange={(
															value,
															...rest
														) =>
															field.onChange(
																value / 100,
																...rest,
															)
														}
													/>
													<span className="text-xs text-mi-neutral-dark">
														100%
													</span>
												</div>
											)}
										/>
									</InputWithLabel>
								</div>
							</div>
						)}
						{watchType === 'Custom_distribution' && (
							<div className="w-full">
								<div className="inline-block space-y-4">
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_distribution_action.distribution"
										label="Distribution"
										id="custom_distribution_action.distribution"
										labelClassName={labelClassName}
									>
										<div className="flex items-center">
											<Controller
												name="custom_distribution_action.limiter"
												control={control}
												rules={{
													required: 'Required field',
												}}
												render={({ field }) => (
													<Dropdown
														id="custom_distribution_action.limiter"
														placeholder="Min/max"
														className="w-full md:mr-3 md:w-32"
														value={field.value}
														onChange={(val) =>
															field.onChange(val)
														}
														options={[
															{
																label: 'Min.',
																value: 'min',
															},
															{
																label: 'Max.',
																value: 'max',
															},
														]}
														error={
															!!formState?.errors
																?.custom_distribution_action
																?.limiter
																?.message
														}
													/>
												)}
											/>
											<Controller
												name="custom_distribution_action.distribution"
												control={control}
												rules={{
													required: 'Required field',
												}}
												render={({ field }) => (
													<TextInput
														id="title"
														className="w-full md:w-24"
														value={field.value}
														onChange={(val) =>
															field.onChange(val)
														}
														error={
															!!formState?.errors
																?.custom_distribution_action
																?.distribution
																?.message
														}
														endAdornment="%"
													/>
												)}
											/>
											<Text
												className="my-4 md:mx-3 md:my-0"
												type="secondary"
											>
												of
											</Text>
											<Controller
												name="custom_distribution_action.type"
												control={control}
												rules={{
													required: 'Required field',
												}}
												render={({ field }) => (
													<Dropdown
														id="custom_distribution_action.type"
														placeholder="Type"
														className="w-full md:w-28"
														value={field.value}
														onChange={(val) =>
															field.onChange(val)
														}
														options={[
															{
																value: 'products',
																label: 'Products',
															},
															{
																value: 'stock',
																label: 'Stock',
															},
														]}
														error={
															!!formState?.errors
																?.custom_distribution_action
																?.type?.message
														}
													/>
												)}
											/>
											<Text
												className="my-4 md:mx-3 md:my-0"
												type="secondary"
											>
												should be discounted at
											</Text>
											<Controller
												name="custom_distribution_action.discount"
												control={control}
												rules={{
													required: 'Required field',
												}}
												render={({ field }) => (
													<Dropdown
														id="custom_distribution_action.discount"
														placeholder="%"
														className="w-full md:w-28"
														value={field.value}
														onChange={(val) =>
															field.onChange(val)
														}
														options={range(
															0,
															95,
															5,
														).map((v) => ({
															value: v / 100,
															label: `${v}%`,
														}))}
														error={
															!!formState?.errors
																?.custom_distribution_action
																?.discount
																?.message
														}
													/>
												)}
											/>
										</div>
									</InputWithLabel>
									<InputWithLabel
										labelPosition="label-above-input"
										htmlFor="custom_distribution_action.field"
										label={
											<Tooltip content="You can enable this checkbox to ensure that the rule is applied for each group of the selected field.">
												<div className="flex items-center">
													Group
													<span className="ml-1.5 inline-block">
														<InfoIcon className="h-3.5" />
													</span>
												</div>
											</Tooltip>
										}
										id="custom_distribution_action.field"
										labelClassName={labelClassName}
									>
										<div className="flex flex-col gap-4">
											<StratosCheckbox
												variant="outline"
												checked={applyRule}
												onChange={() =>
													setApplyRule(!applyRule)
												}
												label="Apply rule for every "
											/>
											<Controller
												name="custom_distribution_action.field"
												control={control}
												rules={{
													required: applyRule
														? 'Required field'
														: false,
												}}
												render={({ field }) => (
													<Dropdown
														placeholder="Field"
														className="w-full md:w-48 lg:w-64"
														value={field.value}
														dropUpDown="up"
														onChange={(val) =>
															field.onChange(val)
														}
														options={repeatingFields?.map(
															(f) => ({
																value: f?.id,
																label: f?.name,
															}),
														)}
														error={
															!!formState?.errors
																?.custom_distribution_action
																?.field?.message
														}
													/>
												)}
											/>
										</div>
									</InputWithLabel>
								</div>
							</div>
						)}
					</div>
				</Conditional>
			</form>
		</>
	);
};

BusinessRulesCreateEdit.propTypes = {
	formRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({ current: PropTypes.any }),
	]),
	setIsLoading: PropTypes.func,
};

BusinessRulesCreateEdit.defaultProps = {
	formRef: null,
	setIsLoading: () => { },
};

export default BusinessRulesCreateEdit;
