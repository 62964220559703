import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
/**
 * A spinning half circle. Indicator for elements which are still waiting to be updated.
 */
const LoadingSpinner = (props) => {
    const { hidden = false, variant = 'sm', antiVisualJumping = true, as = 'div', className = '' } = props;
    const Tag = as;
    return hidden && !antiVisualJumping ? (_jsx(_Fragment, {})) : (_jsx(Tag, { className: cn('inline-block rounded-full animate-[spin_0.6s_linear_infinite] border-l-ca-purple border-t-ca-purple border-r-transparent border-b-transparent transition-opacity', (variant === 'sm' || variant === undefined) &&
            'mx-1 w-2 h-2 border-2', props?.variant === 'md' && 'mx-2 w-4 h-4 border-2', props?.variant === 'lg' && 'mx-2 w-6 h-6 border-2', antiVisualJumping && hidden ? 'opacity-0' : 'opacity-100', className) }));
};
export default LoadingSpinner;
