import { GET_CUMULIO_PREDICTION_EVALUATION_SSO } from 'api/reports';
import FullCumulioDashboard from 'components/FullCumulioDashboard/FullCumulioDashboard';

const PredictionEvaluationDashboard = () => {
	return (
		<FullCumulioDashboard
			// @ts-ignore
			queryKey={['cumulio-prediction-evaluation']}
			ssoQuery={() => GET_CUMULIO_PREDICTION_EVALUATION_SSO()}
			showLoading
		/>
	);
};

export default PredictionEvaluationDashboard;
