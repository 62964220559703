/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import * as React from 'react';
import ResidualValuePresetSlider from '../components/ResidualValuePresetSlider';
import { GraphData, ScenariosDataType } from '../types/strategies';

type ObjectivesSliderProps = {
	options: ScenariosDataType;
	value: number;
	onChange: (val: number) => void;
	updateScenariosForObjective: (customData: GraphData) => void;
};

const getSelected = (options: ScenariosDataType, value: number) => {
	const found = options.find((opt) => opt.intensity_level === value);
	if (!found) {
		return;
	}
	return {
		max: found.max_residual_value,
		min: found.min_residual_value!,
		sell: found.sell_through_target ?? 100,
	};
};

// TODO we shouldn't need this
// just for testing and to ensure there is some value
const SOME_VALUE = { min: 0, max: 100, sell: 90 };

export const ObjectivesSlider = ({
	options,
	value,
	onChange,
	updateScenariosForObjective,
}: ObjectivesSliderProps) => {
	const [selected, setSelected] = React.useState(
		() => getSelected(options, value) ?? SOME_VALUE,
	);
	const [graphState, setGraphState] = React.useState<GraphData>(selected);

	const onSaveCustom = () => {
		// const auc = calculateAuc(graphState.max, graphState.min, graphState.sell);
		// const replaceIdx = findClosestScenarioIndex({
		// 	scenarios: options,
		// 	targetAuc: auc,
		// });
		// console.log('saved', { auc }, { replaceIdx });

		// update options with this new setting
		updateScenariosForObjective(graphState);
	};

	const onGraphChange = (newValue: GraphData) => {
		// const auc = calculateAuc(newValue.max, newValue.min, newValue.sell);
		// console.log('onGraphChange', { auc });

		setGraphState(newValue);
	};

	const onSliderChange = (val: number) => {
		const newSelected = getSelected(options, val) ?? SOME_VALUE;
		setSelected(newSelected);
		setGraphState(newSelected);
		onChange(val);
		// TODO fix that type
		return {};
	};

	return (
		<div className="mb-3 w-full flex-grow pr-6">
			<ResidualValuePresetSlider
				className="w-full"
				options={options as any}
				value={value}
				// maxResidualValue={objValues.max_residual_value}
				// minResidualValue={objValues.min_residual_value}
				// sellThroughTarget={objValues.sell_through_target}
				styling="new"
				onChange={onSliderChange}
				onSaveCustom={onSaveCustom}
				valueCustom={graphState}
				onChangeCustom={onGraphChange}
			/>
		</div>
	);
};
