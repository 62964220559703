import {
	FormValues,
	PhaseDataType,
	PhaseGroupType,
	PhaseType,
	SeasonDataType,
	SeasonPhasesType,
	SeasonType,
} from '../types/seasons';
import { END_OF_SEASON_PHASE_CONSTANTS } from './utils';

import { isWeekAfter, today as getToday } from 'crunch-utils';
import dayjs from 'dayjs';
import { UseFormReturn } from 'react-hook-form';

export const constructEndSeasonFakePhase = (endDate: SeasonType['endDate']) => {
	return {
		...END_OF_SEASON_PHASE_CONSTANTS,
		startDate: endDate,
		start_date: endDate.format('YYYY-MM-DD'),
	};
};

export const transformSeason = (data: SeasonDataType) => {
	const today = getToday();
	const fixedStartDate =
		window._ENV_.REACT_APP_FIXED_T_OF_INTEREST === false
			? today
			: dayjs(window._ENV_.REACT_APP_FIXED_T_OF_INTEREST.toString());

	const endDate = dayjs(data.end_date);

	return {
		...data,
		endDate,
		isInPast: !isWeekAfter({ date: endDate, endDate: fixedStartDate }),
	};
};

export const transformPhasesData = ({
	phases,
	seasonEndDate,
	type,
}: {
	phases: PhaseType[];
	seasonEndDate: dayjs.Dayjs;
	type: PhaseGroupType;
}) => {
	if (type === 'beforeNow') {
		return phases.map((item) => ({
			...item,
			hasInput: item.type === 'saved' || item.type === 'new',
		}));
	}

	const afterNow: PhaseType[] = phases.map((item) => ({
		...item,
		hasInput: item.type === 'saved' || item.type === 'new',
	}));
	afterNow.push(constructEndSeasonFakePhase(seasonEndDate));

	return afterNow;
};

export const convertPhaseTypeToPhaseDataType = (
	phaseTypes: PhaseType[],
): PhaseDataType[] => {
	return phaseTypes.map(
		(phaseType) =>
			({
				completed: phaseType.completed,
				id: phaseType.id,
				name: phaseType.name,
				start_date: phaseType.start_date,
			}) as PhaseDataType,
	);
};

/**
 *  1:1 version of props.phases BUT takes into account **local changes** (user changed start_date of a phase for example)
 * - Does not include (faked) end of season phase
 */
export const constructPhasesFromForm = (
	getFormValues: UseFormReturn<FormValues>['getValues'],
): SeasonPhasesType => {
	const formValues = getFormValues();
	return {
		afterNow: formValues.afterNow.filter(
			(phase) => phase.type !== 'endseason' && phase.type !== 'deleted',
		),
		beforeNow: formValues.beforeNow.filter(
			(phase) => phase.type !== 'deleted',
		),
	};
};

export const constructSeasonFromForm = (
	getFormValues: UseFormReturn<FormValues>['getValues'],
	newEndDate?: SeasonType['endDate'],
): SeasonType => {
	const formValues = getFormValues();
	const endPhase = [...formValues.afterNow, ...formValues.beforeNow].find(
		(formItem) => formItem.id === END_OF_SEASON_PHASE_CONSTANTS.id,
	);
	if (!endPhase) {
		throw new Error(
			'[SeasonSettingsModal::constructSeasonFromForm] end of season phase not found in formItems (impossible)',
		);
	}

	return transformSeason({
		end_date: newEndDate?.format('YYYY-MM-DD') ?? endPhase.start_date,
		id: endPhase.id,
		name: endPhase.name,
		include_return_cost: formValues.includeReturnCost,
		include_shipping_cost: formValues.includeShippingCost,
	});
};

export const validatePhases = (
	beforeNow: PhaseType[],
	afterNow: PhaseType[],
) => {
	const combinedNames = [...beforeNow, ...afterNow]
		.filter((p) => p.type !== 'deleted')
		.map((p) => p.name);

	if (combinedNames.length !== new Set(combinedNames).size) {
		// Duplicate names
		return false;
	}

	return combinedNames.every((name) => name !== '');
};
