import { jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
import Text from '../Text/Text';
const Title = ({ children, className, }) => (_jsx(Text, { as: 'h2', className: cn('mb-8', 'text-3xl', 'mb-4', 'font-gazpacho', 'text-mi-night-forest', 'leading-[120%]', className), children: children }));
const Content = ({ className = '', children, unstyled = false }) => (_jsx("div", { className: cn(!unstyled && 'p-10', className), children: children }));
const Actions = ({ children, className = '', unstyled = false, }) => {
    return (_jsx("div", { className: cn(!unstyled && 'p-4 rounded-b-[inherit] bg-ca-ghost-white', className), children: children }));
};
const Root = ({ children, className = '', unstyled = false, }) => {
    return (_jsx("div", { className: cn(!unstyled && 'max-w-lg bg-white rounded-lg shadow-2xl', className), children: children }));
};
export default { Root, Title, Content, Actions };
