import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import cn from '../../utils/cn';
const defaultProps = {
    size: 'regular',
    endAdornment: '',
    startAdornment: '',
};
const TextInput = (definedProps) => {
    const { id, type, size, startAdornment, endAdornment, error, className, fullHeight, ...props } = { ...defaultProps, ...definedProps };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [rootElement, setRootElement] = useState();
    const [inputElement, setInputElement] = useState();
    const [isValidDate, setIsValidDate] = useState(false);
    useEffect(() => {
        if (type === 'date') {
            // eslint-disable-next-line react/prop-types
            setIsValidDate(!!props?.value);
        }
        // eslint-disable-next-line react/prop-types
    }, [type, props?.value]);
    useEffect(() => {
        const onFocus = () => {
            if (inputElement) {
                inputElement.focus();
            }
            if (props?.onFocus && inputElement) {
                props.onFocus(inputElement);
            }
        };
        const onBlur = () => {
            if (props.onBlur && inputElement) {
                props.onBlur(inputElement);
            }
        };
        if (rootElement) {
            rootElement.addEventListener('focus', onFocus);
            if (props?.onBlur)
                rootElement.addEventListener('blur', onBlur);
        }
        return () => {
            if (rootElement) {
                rootElement.removeEventListener('focus', onFocus);
                if (props?.onBlur)
                    rootElement.removeEventListener('blur', onBlur);
            }
        };
    }, [rootElement, inputElement]);
    return (_jsxs("div", { className: cn('relative inline-flex flex-wrap', className), children: [_jsxs("div", { ref: setRootElement, tabIndex: 0, role: "textbox", className: cn('relative w-full overflow-hidden border rounded-lg transition-colors focus:outline-none focus-within:ring-4 focus-within:ring-opacity-10', !error &&
                    'text-ca-gray border-ca-silver focus-within:border-ca-purple focus-within:ring-ca-purple focus-within:text-ca-black', error && 'text-ca-red border-ca-red focus-within:ring-ca-red', fullHeight === true && 'h-full', fullHeight !== true && size === 'regular' && 'h-10', fullHeight !== true && size === 'small' && 'h-8'), children: [startAdornment && (_jsx("span", { className: cn('absolute left-0 -top-px bg-ca-silver rounded-lg rounded-r-none text-center', size === 'regular' && 'px-4 text-sm', size === 'small' && 'px-4 text-xs', error && 'bg-ca-red text-white'), style: {
                            width: size === 'regular' ? '42px' : '26px',
                            height: size === 'regular' ? '42px' : '26px',
                            lineHeight: size === 'regular' ? '42px' : '26px',
                        }, children: startAdornment })), _jsx("input", { ref: setInputElement, className: cn('w-full h-full block placeholder-ca-gray bg-white leading-none focus:outline-none', {
                            'text-ca-black': !error && (type !== 'date' || isValidDate),
                        }, {
                            'text-ca-gray': !error && type === 'date' && !isValidDate,
                        }, { 'text-ca-red': error }, size === 'regular' && 'px-4 py-3 text-sm', size === 'small' && 'px-4 text-xs'), style: {
                            marginLeft: cn({
                                '42px': startAdornment && size === 'regular',
                                '26px': startAdornment && size === 'small',
                            }),
                            marginRight: cn({
                                '42px': endAdornment && size === 'regular',
                                '26px': endAdornment && size === 'small',
                            }),
                        }, name: id, type: type, ...props, onChange: (e) => {
                            if (type === 'date') {
                                setIsValidDate(!!e.target.value);
                            }
                            if (!props.onChange) {
                                return;
                            }
                            props.onChange(e);
                        } }), endAdornment && (_jsx("span", { className: cn('absolute right-0 -top-px bg-ca-silver rounded-lg rounded-l-none text-center', size === 'regular' && 'px-4 text-sm', size === 'small' && 'px-2 text-xs', error && 'bg-ca-red text-white'), style: {
                            width: size === 'regular' ? '42px' : '26px',
                            height: size === 'regular' ? '42px' : '26px',
                            lineHeight: size === 'regular' ? '42px' : '26px',
                        }, children: endAdornment }))] }), error && typeof error === 'string' && (_jsx("span", { className: "w-full mt-1 pr-1 text-ca-red text-xs text-right absolute top-full", children: error }))] }));
};
export default TextInput;
