import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useRef, useState } from 'react';
// @ts-ignore
import { useDrag, useDrop } from 'react-dnd';
import Checkbox from '../Checkbox/Checkbox';
import DragDropCell from './DragDropCell';
import cn from '../../utils/cn';
;
const TableRow = ({ headings = [], index, row = { id: '' }, rowKey = 'id', disabled = [], selected = [], selectable = false, loading = false, dragDisabled = [], dndType, dndAccepts, handleCheckedChanged = () => { }, handleRowMovedPreview = () => { }, handleRowMoved = () => { }, renderCell, handleClick, handleMouseOver, handleMouseLeave, }) => {
    const ref = useRef(null);
    const [canDrag, setCanDrag] = useState(false);
    const [{ handlerId }, drop] = useDrop({
        accept: dndAccepts ?? 'TABLE_ROW',
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover: (item, monitor) => {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return;
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            // Get vertical middle
            const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            // Determine mouse position
            const clientOffset = monitor.getClientOffset();
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top;
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            // dont allow dragging of items that have their index present in `dragDisabled`
            if (dragDisabled.includes(dragIndex) ||
                dragDisabled.includes(hoverIndex)) {
                return;
            }
            handleRowMovedPreview(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            // eslint-disable-next-line no-param-reassign
            item.index = hoverIndex;
        },
    });
    const [{ isDragging }, drag] = useDrag({
        type: dndType ?? 'TABLE_ROW',
        canDrag: canDrag && !dragDisabled.includes(index),
        item: () => {
            return { id: row?.[rowKey], index };
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
        end: handleRowMoved,
    });
    const isSelectable = selectable && !loading;
    drag(drop(ref));
    return (_jsxs("tr", { ref: ref, className: cn('relative transition-colors group/row not-last:border-b not-last:border-gray-200 not-last:relative', disabled.includes(row?.[rowKey]) && 'opacity-50 cursor-not-allowed', isDragging && 'opacity-50 bg-purple-50 cursor-grabbing bg-opacity-20', handleClick && !isDragging && 'cursor-pointer'), onClick: handleClick ? (event) => handleClick(row, event) : undefined, onMouseOver: handleMouseOver ? () => handleMouseOver(row) : undefined, onMouseLeave: handleMouseLeave ? () => handleMouseLeave(row) : undefined, onFocus: handleMouseOver ? () => handleMouseOver(row) : undefined, "data-handler-id": handlerId, children: [isSelectable && (_jsx("td", { className: "pl-5 pr-3", children: _jsx(Checkbox, { disabled: disabled.includes(row?.[rowKey]), checked: selected.includes(row?.[rowKey]), onChange: (checked) => handleCheckedChanged(row?.[rowKey], checked) }) }, `selectable${row?.[rowKey]}`)), headings.map((col, colIndex) => {
                if (col.id === 'dragdrop') {
                    return (_jsx(DragDropCell, { disabled: dragDisabled.includes(index), onMouseEnter: () => setCanDrag(true), onMouseLeave: () => setCanDrag(false), dragRef: drag }, `${row?.[rowKey]}_dragdrop`));
                }
                return (_jsx("td", { className: cn(`text-sm text-ca-black leading-none whitespace-nowrap select-text relative transition-colors p-3`, handleClick
                        ? `group-hover/row:bg-purple-50`
                        : 'group-hover/row:bg-gray-50', {
                        'text-left': col?.align === 'left' || !col?.align,
                        'text-right': col?.align === 'right',
                        'text-center': col?.align === 'center',
                    }, colIndex === 0 && !isSelectable && 'pl-5', colIndex === headings.length - 1 && 'pr-5'), children: renderCell(row, col.id, index) }, `${row?.[rowKey]}_${col.id}`));
            })] }, `${row?.[rowKey]}`));
};
export default TableRow;
