import { NavLink, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import {
	ActualsIcon,
	BarChartIcon,
	ChartScatterBoldIcon,
	cn,
	FlaskIcon,
	HelpIcon,
	Logo,
	Tooltip,
	StrategyIcon,
	LogOutIcon,
	UploadBoldIcon,
	UserManagementBoldIcon,
} from 'crunch-components';

import useChannelStore from 'hooks/channels/useChannelStore';
import useLogout from 'hooks/useLogout';
import { isFeatureEnabled } from 'utils/featureUtils';
import { DEFAULT_PATHS } from 'utils/routerUtils';

interface SidebarNavLinkProps {
	icon: React.ElementType;
	to: string;
	label?: string;
	disabled?: boolean;
	tooltip?: string;
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
	target?: React.HTMLAttributeAnchorTarget;
};

const SidebarNavLink: React.FC<SidebarNavLinkProps> = (props) => {
	const { icon: Icon, to, disabled, label, tooltip } = props;
	return (
		<Tooltip content={tooltip} placement="right">
			<NavLink
				to={to || ''}
				className={({ isActive, isPending }) =>
					cn(
						'group/li',
						isActive ? 'active' : 'not-active',
						isPending ? 'pending' : 'not-pending',
						(disabled ?? false)
							? 'cursor-not-allowed opacity-50'
							: 'cursor-pointer hover:text-ca-purple',
					)
				}
			>
				<li className='relative flex cursor-pointer flex-col flex-wrap items-center py-5 text-ca-gray transition-all before:absolute before:left-0 before:top-0 before:h-full before:bg-ca-purple before:transition-all hover:border-ca-purple hover:text-ca-purple group-[.active]/li:before:w-1 group-[.not-active]/li:before:w-0'>
					<Icon className='w-5 transition-colors group-[.active]/li:text-ca-purple' />
					<span className="mt-4 h-0 max-w-4/5 text-center text-xs font-semibold uppercase tracking-tighter opacity-0 transition-all group-hover/ul:h-4 group-hover:h-4 group-hover/ul:opacity-100 group-[.active]/li:h-4 group-[.active]/li:text-ca-purple group-[.active]/li:opacity-100">
						{label}
					</span>
				</li>
			</NavLink>
		</Tooltip>
	);
};

export const SidebarNavCircle: React.FC<SidebarNavLinkProps> = (props) => {
	const { icon: Icon, to, tooltip, ...others } = props;
	return (
		<Tooltip content={tooltip}>
			<NavLink
				to={to}
				className={({ isActive }) =>
					cn(
						'mb-2 rounded-full border-2 p-4 transition-colors hover:border-ca-purple hover:text-ca-purple focus:border-ca-purple',
						isActive
							? 'border-ca-purple text-ca-purple'
							: 'border-ca-silver text-ca-gray',
					)
				}
				{...others}
			>
				<Icon className={'w-4'} />
			</NavLink>
		</Tooltip>
	);
}

const LogoNav: React.FC = () => {
	const navigate = useNavigate();

	return (
		<div
			role="button"
			tabIndex={0}
			className="relative cursor-pointer"
			onClick={() => navigate(DEFAULT_PATHS['private'])}
			onKeyDown={() => navigate(DEFAULT_PATHS['private'])}
		>
			<Logo className="h-full w-12" />
		</div>
	);
}

export default function StratosSidebar() {
	const { user } = useAuth0();
	const { activeChannel } = useChannelStore();

	const isSuperAdmin =
		user?.[window._ENV_.REACT_APP_ROLES_CLAIM]?.includes('superadmin');

	const isDevEnv = ['dev', 'development', 'local'].includes(
		window._ENV_.REACT_APP_ENVIRONMENT,
	);

	return (
		<div className="sticky top-0 z-10 flex h-full min-h-screen w-40 shrink-0 flex-col justify-between border-r border-ca-black border-opacity-5 bg-white">
			<div>
				<div className="flex justify-center py-10">
					<LogoNav />
				</div>
				<ul className="group/ul flex flex-col py-10">
					{isFeatureEnabled('actuals', activeChannel) && (
						<SidebarNavLink
							icon={ActualsIcon}
							label="Actuals"
							to="/actuals"
						/>
					)}
					{isFeatureEnabled('strategy', activeChannel) && (
						<SidebarNavLink
							icon={StrategyIcon}
							label="Strategy"
							to="/strategy"
						/>
					)}
					{isFeatureEnabled('recommendations', activeChannel) && (
						<SidebarNavLink
							icon={BarChartIcon}
							label="Recommendation"
							to="/recommendations"
						/>
					)}

					{isFeatureEnabled('global', activeChannel) && (
						<SidebarNavLink
							icon={ActualsIcon}
							label="Global"
							to="/global"
						/>
					)}
				</ul>
			</div>
			<div className="flex flex-col items-center justify-center p-10">
				{isSuperAdmin && isDevEnv && (
					<SidebarNavCircle
						tooltip="Prediction evaluation"
						icon={ChartScatterBoldIcon}
						to="prediction-evaluation"
					/>
				)}
				{isSuperAdmin && (
					<SidebarNavCircle
						tooltip="User management"
						icon={UserManagementBoldIcon}
						to="user-management"
					/>
				)}
				{isSuperAdmin && (
					<SidebarNavCircle
						tooltip="Feature Flags"
						icon={FlaskIcon}
						to='feature-flags'
					/>
				)}
				{isFeatureEnabled('uploader', activeChannel) && (
					<SidebarNavCircle
						tooltip="Upload data"
						icon={UploadBoldIcon}
						to="uploads"
					/>
				)}
				<SidebarNavCircle
					tooltip="Link to Knowledge Base"
					icon={HelpIcon}
					to='http://knowledge.crunchplatform.eu'
					target='_blank'
				/>
				<StratosLogOutButton />
			</div>
		</div>
	);
}

export const StratosLogOutButton = () => {
	const logoutFn = useLogout();
	const handleLogout = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		logoutFn();
	}
	return <SidebarNavCircle to='' tooltip='Log out' icon={LogOutIcon} onClick={handleLogout} />;
};
