import { Conditional, InputWithLabel, ProductIcon } from 'crunch-components';
import { ReactNode } from 'react';

const ConditionLabel = ({ children }: { children: NonNullable<ReactNode> }) => (
	<Conditional
		classNameLabel="bg-mi-velvet-lilac-lighter text-mi-velvet-lilac rounded-none rounded-br"
		classNameLeftBorder="border-mi-velvet-lilac-lighter"
		label={
			<span className="flex items-center gap-2">
				<ProductIcon className="h-4 w-4" /> SELECT PRODUCTS
			</span>
		}
	>
		<InputWithLabel
			className="mb-4"
			labelPosition="label-above-input"
			labelClassName="w-60 mt-2.5"
			label="Filter"
			htmlFor="filter"
		>
			<div className="space-y-5">{children}</div>
		</InputWithLabel>
	</Conditional>
);

export default ConditionLabel;
