import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Transition } from '@headlessui/react';
import clsx from 'clsx';
import { createContext, Fragment, useCallback, useState } from 'react';
import { Outlet } from 'react-router-dom';
import useKeyPress from '../hooks/useKeyPress';
const ModalContext = createContext({
    open: () => {
        throw new Error('[ModalContext::open] ModalContext not initialized');
    },
    close: () => {
        throw new Error('[ModalContext::close] ModalContext not initialized');
    },
});
const ModalProvider = () => {
    const [modal, setModal] = useState();
    const [sizeClass, setSizeClass] = useState();
    const [operationMode, setOperationMode] = useState();
    const [identifier, setIdentifier] = useState(undefined);
    const [onCloseFn, setOnCloseFn] = useState(undefined);
    const open = ({ modalNode, id, mode, onClose, sizeClass: sC, }) => {
        setModal(modalNode);
        setSizeClass(sC);
        setOperationMode(mode);
        setIdentifier(id);
        setOnCloseFn(() => {
            return onClose;
        });
        document.body.style.overflow = 'hidden';
    };
    const close = useCallback((id = undefined) => {
        if (id !== undefined && identifier !== id) {
            return;
        }
        if (onCloseFn) {
            onCloseFn();
        }
        setModal(null);
        setSizeClass(undefined);
        setOperationMode(undefined);
        setOnCloseFn(undefined);
        setIdentifier(undefined);
        document.body.style.overflow = '';
    }, [onCloseFn, modal, identifier]);
    const nonPriorityClose = () => {
        if (operationMode !== 'priority') {
            close(identifier);
        }
    };
    useKeyPress('Escape', nonPriorityClose);
    return (_jsxs(ModalContext.Provider, { value: { open, close }, children: [_jsx(Outlet, {}), _jsx(Transition, { appear: true, show: !!modal, as: Fragment, children: _jsxs("div", { className: "z-40 fixed left-0 right-0 bottom-0 top-0", children: [_jsx("div", { role: "presentation", className: "absolute left-0 right-0 bottom-0 top-0 backdrop-filter backdrop-blur-sm", style: { background: 'rgba(230, 230, 230, 0.5)' }, onClick: nonPriorityClose }), _jsx("div", { className: clsx('absolute left-1/2  transform top-[10%] -translate-x-1/2', sizeClass), children: _jsx(Transition.Child, { enter: "transition ease-out duration-100", enterFrom: "transform opacity-0 scale-95", enterTo: "transform opacity-100 scale-100", leave: "transition ease-in duration-75", leaveFrom: "transform opacity-100 scale-100", leaveTo: "transform opacity-0 scale-95", children: _jsx("div", { children: modal }) }) })] }) })] }));
};
export { ModalContext, ModalProvider };
