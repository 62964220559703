// #region Color Primitives
export const PURE_WHITE = '#ffffff';
export const PURE_BLACK = '#000000';
export const VELVET_LILAC = '#9268EA';
export const VIOLET_HEAVEN = '#CBB4FC';
export const LILAC_FROST = '#EBE1FF';
export const LAVENDER_HAZE = '#F5F0FE';
export const VELVET_HAZE = '#FDF8FF';
export const OCEAN_TEAL = '#509F9F';
export const OCEAN_BREEZE = '#80CFCF';
export const ICY_BREEZE = '#C4F2F2';
export const POLAR_MIST = '#DCFAFA';
export const FROSTED_PEARL = '#F3FCFC';
export const FALU_RED = '#7B2B26';
export const POPPY_BLOOM = '#D23E3D';
export const IMPERIAL_RED = '#EF4444';
export const DARK_CORAL = '#F7A1A1';
export const LIGHT_CORAL = '#FDE8E8';
export const NIGHT_FOREST = '#0F2929';
export const DAY_FOREST = '#0F5645';
export const SPRING_MOSS = '#91BE57';
export const LIME_MIST = '#DCFCB4';
export const FRESH_PEAR = '#EBFDD4';
export const SOFT_MEADOW = '#F9FFF1';
export const NIGHT = '#2B2B3A';
export const ANTHRACITE = '#4B4B55';
export const SPACE = '#ABABBD';
export const MIST = '#F2F0F5';
export const PEARL = '#F6F5F9';
export const SHADOW = '#e6e6e6';
// #region Palette
export const P_VIOLET_HEAVEN = {
    100: VELVET_HAZE,
    200: LAVENDER_HAZE,
    300: LILAC_FROST,
    400: VIOLET_HEAVEN,
    500: VELVET_LILAC,
};
export const P_IMPERIAL_RED_ = {
    100: LIGHT_CORAL,
    200: DARK_CORAL,
    300: IMPERIAL_RED,
    400: POPPY_BLOOM,
    500: FALU_RED,
};
export const P_ICY_BREEZE = {
    100: FROSTED_PEARL,
    200: POLAR_MIST,
    300: ICY_BREEZE,
    400: OCEAN_BREEZE,
    500: OCEAN_TEAL,
};
export const P_LIME_MIST = {
    100: SOFT_MEADOW,
    200: FRESH_PEAR,
    300: LIME_MIST,
    400: SPRING_MOSS,
    500: DAY_FOREST,
};
export const NEUTRALS = {
    100: PEARL,
    200: MIST,
    300: SPACE,
    400: ANTHRACITE,
    500: NIGHT
};
// #region MarkmiUI Tailwind Config
export default {
    'gray-700': '#4b4b55' /* not the same as tailwind gray-700! */,
    /* Primary colors */
    'night-forest': NIGHT_FOREST,
    'day-forest': DAY_FOREST,
    // velvet
    'velvet-lilac': VELVET_LILAC,
    'velvet-lilac-light': VIOLET_HEAVEN,
    'velvet-lilac-lighter': LILAC_FROST,
    'velvet-lilac-lightest': LAVENDER_HAZE,
    // lime
    'lime-mist': LIME_MIST,
    'lime-mist-light': FRESH_PEAR /* used as tag background, image background*/,
    'lime-mist-lighter': SOFT_MEADOW /* used as background */,
    'lime-mist-dark': SPRING_MOSS,
    // neutral
    neutral: SPACE,
    'neutral-light': MIST,
    'neutral-lighter': PEARL /* used as gray background */,
    'neutral-lightest': PURE_WHITE,
    'neutral-dark': '#767676' /* Not in design guide colors - but used for font in design guide itself */,
    'neutral-darker': ANTHRACITE,
    'neutral-darkest': NIGHT,
    'neutral-darkest-est': PURE_BLACK,
    /* Secondary colors */
    sec: {
        'icy-breeze': ICY_BREEZE,
        'icy-breeze-dark': OCEAN_TEAL,
        'icy-breeze-light': POLAR_MIST,
        'icy-breeze-lighter': FROSTED_PEARL,
    }
};
