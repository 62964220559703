
import { Tooltip, cn } from 'crunch-components';

interface FilterTagProps {
	field: string;
	value: string | Array<string>;
	warning?: boolean;
	warningText?: string;
	onClick?: () => void;
};

const FilterTag = ({
	field,
	value,
	onClick,
	warning = false,
	warningText
}: FilterTagProps) => (
	<Tooltip content={warning ? warningText : undefined}>
		<button
			className={cn(
				'truncate max-w-md p-2 text-xs rounded-lg transition-colors leading-none focus:outline-none',
				'focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple focus-visible:bg-ca-silver-a',
				'hover:bg-ca-silver-a active:bg-ca-silver-a',
				warning ? 'bg-ca-gray-200 text-ca-gray' : 'bg-ca-silver text-ca-black'
			)}
			type="button"
			onClick={onClick}
		>
			{field} <span className="text-ca-gray">is</span>{' '}
			{Array.isArray(value) ? value?.join(', ') : value}
		</button>
	</Tooltip>
);

export default FilterTag;
