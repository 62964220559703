// #region MarkmiUI
import defaultTheme from 'tailwindcss/defaultTheme';
import plugin from 'tailwindcss/plugin';
import * as miColorsTailwind from '../styles/colors';
export default {
    safelist: [{ pattern: /grid-cols-./ }],
    darkMode: 'selector',
    theme: {
        extend: {
            fontFamily: {
                sans: ['Inter var', ...defaultTheme.fontFamily.sans], //TODO!
            },
            fontSize: {
                xxs: '.625rem',
                md: ['.9375rem', '1.375rem'],
                'ca-screen': ['2rem', '2.5rem'],
                'ca-section': ['1.375rem', '1.625rem'],
                'ca-subsection': ['1.225rem', '1.425rem'],
            },
            boxShadow: {
                'checkbox-hover-off': '0px 0px 0px -2px #6111C74B',
                'checkbox-hover': '0px 0px 8px 1px #6111C74B',
                ca: '0 2px 5px 0 rgba(0, 0, 0, 0.11), 0 0 0 1px rgba(0, 0, 0, 0.04)',
                // fake shadow to mimic a border on elements like <table>
                'ca-hack-silver': '0 0 0 1px #E9E8EB',
                'ca-glow-off': 'rgba(95, 17, 197, 0) 0px 0px 0px -2px',
                'ca-glow': 'rgba(95, 17, 197, 0.2) 0px 0px 24px',
            },
            textColor: {
                badge: {
                    gray: '#71717a',
                    blue: '#1e40af',
                    indigo: '#3730a3',
                    purple: '#6b21a8',
                    orange: '#F97316',
                    yellow: '#ca8a04',
                    appelblauwzeegroen: '#0d9488',
                    green: '#0EC075',
                    fuchsia: '#d946ef',
                    pink: '#be185d',
                    lime: '#4d7c0f',
                    ice: '#155e75',
                },
            },
            backgroundColor: {
                badge: {
                    gray: '#e4e4e7',
                    green: '#0EC07514',
                    blue: '#dbeafe',
                    indigo: '#e0e7ff',
                    fuchsia: '#fae8ff',
                    pink: '#fce7f3',
                    orange: '#F9731614',
                    purple: '#e9d5ff',
                    yellow: '#fef9c3',
                    appelblauwzeegroen: '#ccfbf1',
                    lime: '#ecfccb',
                    ice: '#cffafe',
                },
            },
            colors: {
                ca: {
                    'ghost-white': '#F5F5F7',
                    black: '#141414',
                    gray: '#8B8B8F',
                    'gray-100': '#F5F5F7',
                    'gray-200': '#F1F1F1',
                    'gray-300': '#DBDBDB',
                    'gray-400': '#B9B9B9',
                    'gray-500': '#8B8B8F',
                    'gray-500-50': 'rgba(55%, 55%, 56%, 50%)',
                    'gray-bg': 'rgba(8%, 8%, 8%, 10%)',
                    silver: '#E9E8EB',
                    purple: '#6111C7',
                    'purple-bg': 'rgba(38%, 7%, 78%, 37%)',
                    'purple-bg-2': 'rgba(38%, 7%, 78%, 20%)',
                    blue: '#046AD7',
                    destructive: '#EF4444',
                    'destructive-bg': 'rgba(94%, 27%, 27%, 20%)',
                    red: '#F21E1E',
                    green: '#0EC075',
                    'green-bg': 'rgba(5%, 75%, 46%, 20%)',
                    orange: '#F97316',
                    'gray-a': '#616167',
                    'silver-a': '#CBC9CE',
                    'purple-a': '#4B1096',
                    'red-a': '#C91414',
                },
                mi: {
                    ...miColorsTailwind.default
                },
                checkbox: {
                    'purple-bg': '#6111C719',
                    'purple-hover': '#7000FF',
                    'purple-bg-hover': '#7000FF41',
                },
            },
            cursor: {
                grab: 'grab',
            },
            minHeight: {
                12: '3rem',
            },
            maxHeight: {
                12: '3rem',
                '95vh': '95vh',
            },
            maxWidth: {
                '4/5': '80%',
            },
            width: {
                128: '32rem',
            },
            keyframes: {
                'linear-progress': {
                    '0%': {
                        width: '0%',
                        transform: 'translateX(0%)',
                    },
                    '50%': {
                        width: '100%',
                        transform: 'translateX(0%)',
                    },
                    '100%': {
                        width: '100%',
                        transform: 'translateX(100%)',
                    },
                },
                'circular-progress': {
                    '0%': {
                        'stroke-dashoffset': 288.5,
                    },
                    '50%': {
                        'stroke-dashoffset': 0,
                    },
                    '100%': {
                        'stroke-dashoffset': -288.5,
                    },
                },
                hit: {
                    '0%': {
                        transform: 'scale(100%)',
                    },
                    '70%': {
                        transform: 'scale(85%)',
                    },
                    '100%': {
                        transform: 'scale(100%)',
                    },
                },
                'background-hit': {
                    '0%': {
                        backgroundColor: 'rgba(97, 17, 199, 0.1)',
                    },
                    '20%': {
                        backgroundColor: 'rgba(97, 17, 199, 0.1)',
                    },
                    '100%': {
                        backgroundColor: 'rgba(97, 17, 199, 0)',
                    },
                },
            },
            animation: {
                'linear-progress': 'linear-progress 2s cubic-bezier(0.65, 0, 0.35, 1) infinite',
                'circular-progress': 'circular-progress 2s cubic-bezier(0.65, 0, 0.35, 1) infinite',
                hit: 'hit 200ms cubic-bezier(0.65, 0, 0.35, 1)',
                'background-hit': 'background-hit 1500ms ease-out',
            },
        },
    },
    plugins: [
        // eslint-disable-next-line
        require('tailwindcss-animate'),
        // eslint-disable-next-line @typescript-eslint/unbound-method
        plugin(({ addVariant }) => {
            addVariant('not-last', '&:not(:last-child)');
        }),
    ],
};
