import { Dropdown, TextInput } from 'crunch-components';
import { Control } from 'react-hook-form';
import { ConditionError, ConditionType, Field, OPERATOR } from './Condition';

type ConditionValueInputProps = {
	id: string;
	onChange: (...event: unknown[]) => void;
	value: ConditionType['value'];
	error?: ConditionError;
	field: Field;
	control: Control;
	operator: ConditionType['operator'];
};

const ConditionValueInput = ({
	id,
	onChange,
	value,
	error,
	field,
	operator,
}: ConditionValueInputProps) => {
	if (
		operator === OPERATOR.IS_LESS_THAN ||
		operator === OPERATOR.IS_GREATER_THAN
	) {
		return (
			<TextInput
				placeholder="Value"
				fullHeight
				id={`condition_value_${id}`}
				type="number"
				min="0"
				step="0.01"
				className="w-full md:w-48 lg:w-64"
				onChange={onChange}
				value={value ?? ''} // Controlled input does not like undefined
				error={error?.value?.message}
			/>
		);
	}

	if (operator === OPERATOR.IS_BEFORE || operator === OPERATOR.IS_AFTER) {
		return (
			<TextInput
				placeholder="Date"
				fullHeight
				type="date"
				id={`condition_value_${id}`}
				className="w-full md:w-48 lg:w-64"
				onChange={onChange}
				value={value ?? ''} // Controlled input does not like undefined
				error={error?.value?.message}
			/>
		);
	}

	if (operator === OPERATOR.IS_IN || operator === OPERATOR.IS_NOT_IN) {
		return (
			<Dropdown
				placeholder="Value"
				className="w-full md:w-48 lg:w-64"
				multiple
				onChange={onChange}
				value={value}
				options={field.options}
				error={error?.value?.message}
				searchable
			/>
		);
	}

	if (operator === OPERATOR.CONTAINS) {
		return (
			<TextInput
				placeholder="Value"
				id={`condition_value_${id}`}
				className="w-full md:w-48 lg:w-64"
				fullHeight
				onChange={onChange}
				value={value ?? ''} // Controlled input does not like undefined
				type="text"
				error={error?.value?.message}
			/>
		);
	}
	throw new Error('Invalid operator');
};

export default ConditionValueInput;
