import api from './api';
import { FeatureFlagObject } from 'pages/feature-flags/types/feature-flags';


export const ALL_FEATURE_FLAGS = (): Promise<FeatureFlagObject[]> => {
	return api.get('api/v2/feature-flags').json();
};

export const ALL_FEATURE_FLAGS_OBJECT = (): Promise<FeatureFlagObject[]> => {
	return api.get('api/v2/feature-flags/object').json();
};

export const PATCH_FEATURE_FLAG = (id: string, newValue: { enabled: boolean }) => {
	return api.patch(`api/v2/feature-flags/${id}`, {
		json: newValue,
	});
};
