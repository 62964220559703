import FeatureFlagsHeader from '../components/FeatureFlagsHeader';
import FeatureFlagsTable from '../components/FeatureFlagsTable';

const FeatureFlagsView = () => {
	return (
		<>
			<FeatureFlagsHeader />
			<div className="mt-10">
				<FeatureFlagsTable />
			</div>
		</>
	);
};

export default FeatureFlagsView;
