import { Route } from 'react-router-dom';
import PredictionEvaluationView from './views/PredictionEvaluation';

// TODO: this should be Capitalized, right?
const predictionEvaluationRouting = (
	<Route
		path="prediction-evaluation"
		element={<PredictionEvaluationView />}
	/>
);

export default predictionEvaluationRouting;
