import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable */
import { Fragment, useEffect, useState } from 'react';
import update from 'immutability-helper';
import Pagination from 'rc-pagination';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import TableRow from './TableRow';
import DragDropCell from './DragDropCell';
import Checkbox from '../Checkbox/Checkbox';
import Dropdown from '../Dropdown/Dropdown';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import Info from '../Icons/Info';
import TriangleDownIcon from '../Icons/TriangleDown';
import TriangeLeftIcon from '../Icons/TriangleLeft';
import TriangleRightIcon from '../Icons/TriangleRight';
import cn from '../../utils/cn';
/** TABLE STYLING MAGIC
 * add background-color and border
 * 		border on row
 * 		bg-color must be on table cells, not row
 * 		AND cell must have 'relative' so the tr.border will render above td/th.border
 * */
export const CELL_PADDING_CLASSNAME = 'p-3';
export const CHILD_ROWS_DEFAULT_STYLING = {
    childRowClassName: 'bg-gray-100 hover:bg-[#eeeff2] transition-all duration-300 ease-out border-y border-gray-200',
    fontClassName: 'text-right text-ca-black text-xs',
};
;
const Table = ({ headings = [], rows: sourceRows = [], rowKey = 'id', separatorRows = [], selectable = false, selected = [], dragDisabled = [], disabled = [], open = [], loading = false, itemsLoading = 10, emptyState = '', isInline = false, maxHeightTable = '', // This is rendered as className which you can give boundaries to the Table markup generated
paginationActivePageClassName = '', renderCell, pagination = {}, onPageChange, onItemsPerPageChange, sort, onSortChange, onSelectedChange, onDragChange, renderOpen, renderOpenTr, onRowClick, onRowMouseOver, onRowMouseLeave, renderAfterRow, className, dndRowTypes = [], dndRowAccepts = [], }) => {
    const [rows, setRows] = useState([]);
    const allChecked = !rows.find((row) => !selected.includes(row?.[rowKey]));
    useEffect(() => {
        setRows(() => sourceRows);
    }, [sourceRows]);
    const handleCheckedChanged = (id, checked) => {
        const args = checked
            ? [...selected, id]
            : selected.filter((rId) => rId !== id);
        onSelectedChange(args);
    };
    const handleCheckAll = () => {
        if (allChecked) {
            onSelectedChange(selected.filter((s) => !rows.find((row) => row?.[rowKey] === s)));
        }
        else {
            onSelectedChange([...selected, ...rows.map((row) => row?.[rowKey])]
                .filter(
            // filter out disabled rows
            (value) => !disabled.includes(value))
                .filter(
            // filter out duplicates
            (value, index, self) => self.indexOf(value) === index));
        }
    };
    const handleRowMoved = () => {
        onDragChange(rows?.map(({ [rowKey]: rKey }) => rKey));
    };
    const handleRowMovedPreview = (dragIndex, hoverIndex) => {
        const updatedRows = update(rows, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, rows[dragIndex]],
            ],
        });
        setRows(updatedRows);
    };
    if (!headings.length)
        return null;
    const isSelectable = selectable && !loading;
    return (_jsx(DndProvider, { backend: HTML5Backend, context: window, children: _jsxs("div", { className: cn('relative bg-white shadow-xl', !isInline ? 'rounded-lg shadow-ca-hack-silver overflow-hidden' : ''), children: [_jsx("div", { className: (cn('overflow-x-auto'), maxHeightTable), children: _jsxs("table", { className: cn('w-full table-auto relative', className), children: [_jsx("thead", { className: "relative after:content[''] after:absolute after:h-[1px] after:bg-ca-silver after:bottom-0 after:right-0 after:left-0 after:z-10", children: _jsxs("tr", { className: 'w-full bg-white z-10 top-0 sticky border-b border-gray-200', children: [isSelectable && (_jsx("th", { className: "pl-5 pr-3 relative", children: _jsx(Checkbox, { checked: allChecked, onChange: handleCheckAll }) }, "check")), headings.map((col, colIndex) => {
                                            if (col.id === 'dragdrop') {
                                                return (_jsx("th", { className: cn('bg-ca-ghost-white relative', col?.maxWidth && `w-[${col?.maxWidth}]`, col?.className), style: { maxWidth: col?.maxWidth ?? 'unset' } }, col.id));
                                            }
                                            return (_jsxs("th", { className: cn('p-3 font-bold text-xs text-ca-black leading-none ', {
                                                    'text-left': col?.align === 'left' || !col?.align,
                                                    'text-right': col?.align === 'right',
                                                    'text-center': col?.align === 'center',
                                                }, colIndex === 0 && !isSelectable && 'pl-5', colIndex === headings.length - 1 && 'pr-5', col?.sortable && 'cursor-pointer', col.className), onClick: () => {
                                                    if (!col?.sortable)
                                                        return;
                                                    if (col?.id === sort?.key) {
                                                        onSortChange({
                                                            key: col?.id,
                                                            direction: sort?.direction === 'asc' ? 'desc' : 'asc',
                                                        });
                                                    }
                                                    else {
                                                        onSortChange({
                                                            key: col?.id,
                                                            direction: 'desc',
                                                        });
                                                    }
                                                }, style: {
                                                    maxWidth: col?.maxWidth ?? 'unset',
                                                }, children: [_jsx(Tooltip, { content: col?.tooltip, children: _jsxs("span", { className: "inline-flex gap-2 items-center", children: [col?.label, col?.tooltip && (_jsx(Info, { className: "h-4 text-gray-400" }))] }) }), col?.id === sort?.key && (_jsx(TriangleDownIcon, { className: cn('inline ml-2 w-2.5 text-ca-gray', sort?.direction === 'asc' && 'transform rotate-180') }))] }, col.id));
                                        })] }) }), _jsxs("tbody", { className: "min-h-12 ", children: [!loading && !rows.length && emptyState ? (_jsx("tr", { className: "relative", children: _jsx("td", { className: "text-center", colSpan: 1000, children: _jsx(Text, { className: "py-24", children: emptyState }) }) })) : null, !loading && (!!rows.length || emptyState)
                                        ? rows.map((row, index) => (_jsxs(Fragment, { children: [(() => {
                                                    const separators = separatorRows.filter((separatorHay) => separatorHay.position === index);
                                                    if (!separators) {
                                                        return null;
                                                    }
                                                    return separators.map((separator) => (_jsxs("tr", { className: cn('text-gray-500 text-xs transition-colors border-b border-gray-200', separator.className), children: [separator.colStart !== undefined ||
                                                                (separator.colStart > 0 && (_jsx("td", { colSpan: separator.colStart }, 'spacer'))), _jsx("td", { colSpan: separator.colSpan ?? 1000, className: "py-1 px-10 bg-gray-100 relative hover:bg-gray-200", children: separator.renderSeparatorRow() }, "content")] }, `separator${separator.key}`)));
                                                })(), _jsxs(Fragment, { children: [_jsx(TableRow, { index: index, row: row, rowKey: rowKey, headings: headings, disabled: disabled, selected: selected, selectable: selectable, loading: loading, handleCheckedChanged: handleCheckedChanged, handleClick: onRowClick, handleMouseOver: onRowMouseOver, handleMouseLeave: onRowMouseLeave, renderCell: renderCell, handleRowMovedPreview: handleRowMovedPreview, handleRowMoved: handleRowMoved, dragDisabled: dragDisabled, dndType: dndRowTypes && dndRowTypes[index], dndAccepts: dndRowTypes && dndRowAccepts[index] }), (() => {
                                                            const isOpen = open?.includes(row?.[rowKey]);
                                                            if (!isOpen) {
                                                                return null;
                                                            }
                                                            if (!renderOpenTr) {
                                                                return (_jsx("tr", { className: cn('relative'), children: _jsx("td", { colSpan: headings.length + (selectable ? 1 : 0), children: renderOpen(row?.[rowKey]) }) }, `${row?.[rowKey]}open${Date.now()}`));
                                                            }
                                                            return renderOpenTr(row, (...args) => renderOpen(args));
                                                        })(), renderAfterRow
                                                            ? renderAfterRow({ row, index })
                                                            : null] }, row?.[rowKey])] }, `fragement${row?.[rowKey]}`)))
                                        : null, loading &&
                                        Array(itemsLoading)
                                            .fill(0)
                                            .map((i, index) => (_jsx("tr", { className: "border-b border-ca-silver relative", children: headings?.map((col, colIndex) => {
                                                if (col.id === 'dragdrop') {
                                                    return (_jsx(DragDropCell, { disabled: true }, `skeleton_loader_${index}_${col.id}`));
                                                }
                                                return (_jsx("td", { className: cn(CELL_PADDING_CLASSNAME, colIndex === 0 && 'pl-5', colIndex === headings.length - 1 && 'pr-5'), children: _jsx("div", { className: "w-full h-6 bg-ca-silver rounded-full animate-pulse" }) }, `skeleton_loader_${index}_${col.id}`));
                                            }) }, `skeleton_loader_${index}`)))] })] }) }), !!Object.keys(pagination).length && (_jsxs("div", { className: "flex justify-between border-t border-ca-silver", children: [_jsx("div", { className: "flex items-center space-x-2 ml-2", children: pagination?.itemsPerPageOptions && (_jsxs(_Fragment, { children: [_jsx(Dropdown, { onChange: onItemsPerPageChange, value: pagination.itemsPerPage, options: pagination.itemsPerPageOptions.map((n) => ({
                                            value: n,
                                            label: n,
                                        })), size: "small", dropUpDown: "up" }), _jsx(Text, { type: "secondary", size: "text-xs", children: "results per page" })] })) }), _jsxs("div", { className: "flex divide-x divide-x-ca-silver", children: [_jsx(Pagination, { className: "flex items-center mr-2.5", current: pagination.currentPage, total: pagination.items, pageSize: pagination.itemsPerPage, onChange: onPageChange, itemRender: (current, type) => {
                                        if (type === 'page' ||
                                            type === 'jump-prev' ||
                                            type === 'jump-next') {
                                            return (_jsx("button", { type: "button", className: cn('py-0.5 px-2 mx-1 text-xs tabular-nums cursor-pointer rounded transition-colors', current === pagination.currentPage
                                                    ? cn('font-bold text-white bg-ca-purple', paginationActivePageClassName)
                                                    : 'text-ca-black hover:bg-ca-silver'), children: type === 'page' ? current : '...' }, current));
                                        }
                                        return null;
                                    } }), _jsx("button", { type: "button", onClick: () => onPageChange(Math.max(pagination.currentPage - 1, 1)), className: cn('p-4 text-ca-gray transition-colors', pagination.currentPage === 1
                                        ? 'cursor-not-allowed'
                                        : 'hover:text-ca-black'), disabled: pagination.currentPage === 1, children: _jsx(TriangeLeftIcon, { className: "w-2" }) }), _jsx("button", { type: "button", onClick: () => onPageChange(Math.min(pagination.currentPage + 1, Math.ceil(pagination.items / pagination.itemsPerPage))), className: cn('p-4 text-ca-gray', pagination.currentPage ===
                                        Math.ceil(pagination.items / pagination.itemsPerPage)
                                        ? 'cursor-not-allowed'
                                        : 'hover:text-ca-black'), disabled: pagination.currentPage ===
                                        Math.ceil(pagination.items / pagination.itemsPerPage), children: _jsx(TriangleRightIcon, { className: "w-2" }) })] })] }))] }) }));
};
export default Table;
