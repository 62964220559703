/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-nested-ternary */
import {
	CREATE_STRATEGY,
	DELETE_STRATEGY,
	RUN_STRATEGY,
} from 'api/strategies-v2';
import dayjs, { Dayjs } from 'dayjs';
import {
	useAllLatestRunStatusesQuery,
	useAllLatestRunStatusesQueryKey,
} from 'hooks/queries/useAllLatestRunStatusesQuery';
import { useMutation, useQueryClient } from 'react-query';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import {
	GET_OVERWRITE_COUNTS,
	GET_OVERWRITE_COUNTS_QUERY_KEY,
	OverwriteCountsDataType,
} from 'api/recommendations-v2';
import {
	Badge,
	BadgeProps,
	Button,
	CheckmarkIcon,
	cn,
	CrossIcon,
	DangerIcon,
	DuplicateIcon,
	EditIcon,
	KebabMenu,
	OpenOverlayIcon,
	PlusIcon,
	ProgressIcon,
	Skeleton,
	TableV2 as Table,
	Text,
	Tooltip,
	TrashIcon,
	useModal,
	useToast,
} from 'crunch-components';
import { isValidKey } from 'crunch-utils';
import useChannelQuery from 'hooks/channels/useChannelQuery';
import useStrategiesWithStatus from 'hooks/queries/useStrategiesWithStatusQuery';
import useStrategyStore from 'hooks/useStrategyStore';
import { PhasesDataType } from '../types/seasons';
import {
	ScenariosDataType,
	StrategiesDataType,
	Strategy,
	StrategyStatusDataType,
} from '../types/strategies';
import { transformPhases } from './queries';
import RunButton from './RunButton';
import { StrategyModal } from './StrategyModal';
import { getPhasesWarnings, getStrategyWarnings } from './strategyWarnings';
import TimeSince from './TimeSince';
import { mapOutdatedReasonToCopy } from './utils';

type StrategiesTableProps = {
	strategies: StrategiesDataType;
	isLoading: boolean;
	phases: PhasesDataType;
	defaultScenarios: ScenariosDataType;
	newStrategyDefault: ScenariosDataType[number];
	// TODO fix
	refetchStrategies: (args?: any) => Promise<any>;
};

const badges: {
	[K in NonNullable<BadgeProps['variant']>]?: React.ReactNode;
} = {
	default: (
		<Badge
			variant="colorPurple"
			className="flex w-40 flex-row justify-center"
		>
			<ProgressIcon className="mr-1 h-2.5 w-auto" />
			Running
		</Badge>
	),
	neutral: (
		<Badge variant="neutral" className="flex w-40 flex-row justify-center">
			Not yet run
		</Badge>
	),
	destructive: (
		<Badge
			variant="destructive"
			className="flex w-40 flex-row justify-center"
		>
			<CrossIcon className="mr-1 h-2.5 w-auto" />
			Failed
		</Badge>
	),
	success: (
		<Badge variant="success" className="flex w-40 flex-row justify-center">
			<CheckmarkIcon className="mr-1 h-2.5 w-auto" />
			Success
		</Badge>
	),
	colorOrange: (
		<Badge
			variant="colorOrange"
			className="flex w-40 flex-row justify-center"
		>
			<DangerIcon className="mr-1 h-2.5 w-auto" />
			Outdated
		</Badge>
	),
};

const HEADINGS = [
	{
		id: 'name',
		label: 'Name',
		align: 'left',
		className: 'w-[260px]',
	},
	{
		id: 'actions',
		label: '',
		align: 'right',
		className: 'w-[125px]',
	},
	{
		id: 'view_result',
		label: 'Result',
		align: 'left',
		className: 'w-[140px]',
	},
	{
		id: 'run_status',
		label: 'Run',
		align: 'left',
		className: 'w-[140px]',
	},
	{ id: 'run_action', label: '', align: 'left' },
] as const;
type ColumnIdType = (typeof HEADINGS)[number]['id'];
type StrategyWithStatus = Strategy &
	(
		| {
				status: StrategyStatusDataType;
				statusState: 'valid';
		  }
		| {
				status: undefined;
				statusState: 'loading' | 'never_ran';
		  }
	);

const getCellRenderer = ({
	handleRunClick,
	handleEditClick,
	handleCopyClick,
	handleDeleteClick,
	isProcessingMutation,
	overwriteCounts,
	noPhasesWarning,
	maxStrategiesWarning,
}: {
	handleRunClick: (strategyId: string) => Promise<unknown>;
	handleEditClick: (strategy: Strategy) => void;
	handleCopyClick: ({
		strategyId,
		name,
	}: {
		strategyId: string;
		name: string;
	}) => void;
	handleDeleteClick: (strategyId: string) => void;
	isProcessingMutation: boolean;
	overwriteCounts: OverwriteCountsDataType | undefined;
	noPhasesWarning: string | undefined;
	maxStrategiesWarning: string | undefined;
}) => {
	const { setActiveStrategy } = useStrategyStore();
	return (row: StrategyWithStatus, columnId: ColumnIdType) => {
		const isLoadingStatus = row.statusState === 'loading';
		const neverRan = row.statusState === 'never_ran';
		const isRunning =
			row.statusState === 'valid' &&
			row.status.simplified_status === 'RUNNING';
		const isDone =
			row.statusState === 'valid' &&
			row.status.simplified_status === 'DONE';
		const isFailed =
			row.statusState === 'valid' &&
			row.status.simplified_status === 'FAILED';
		const isOutdated =
			row.statusState === 'valid' &&
			row.status.simplified_status === 'OUTDATED';

		switch (columnId) {
			case 'view_result': {
				if (isLoadingStatus) {
					return <Skeleton className="h-5 w-full" />;
				}
				if (isDone || isOutdated) {
					return (
						<Link
							to={{
								pathname: '/recommendations/strategy-details',
							}}
							state={{
								id: row.id,
								strategyId: row.id,
								strategy: row.name,
							}}
							onClick={() => {
								setActiveStrategy(row.id);
							}}
							className={cn(
								'flex flex-row items-center text-sm text-ca-gray-500',
								'cursor-pointer hover:underline',
							)}
						>
							<OpenOverlayIcon className="mr-2 w-3" />
							View results
						</Link>
					);
				}

				return (
					<span
						className={cn(
							'flex flex-row items-center text-sm text-ca-gray-500',
							'opacity-50',
						)}
					>
						<OpenOverlayIcon className="mr-2 w-3" />
						View results
					</span>
				);
			}

			case 'actions': {
				const isEditDisabled =
					!!noPhasesWarning || isLoadingStatus || isRunning;
				const isCopyDisabled =
					!!noPhasesWarning || !!maxStrategiesWarning;

				return (
					<KebabMenu
						closeDelayMs={250}
						options={[
							{
								Icon: EditIcon,
								label: 'Edit',
								disabled: isEditDisabled,
								onClick: () => {
									handleEditClick(row);
								},
							},
							{
								Icon: DuplicateIcon,
								label: 'Copy',
								onClick: () => {
									handleCopyClick({
										strategyId: row.id,
										name: `Copy of ${row.name}`,
									});
								},
								disabled: isCopyDisabled,
							},
							{
								Icon: TrashIcon,
								label: 'Delete',
								onClick: () => {
									handleDeleteClick(row.id);
								},
								hasSafety: true,
							},
						]}
					/>
				);
			}

			case 'run_status': {
				let variant: BadgeProps['variant'] | 'skeleton' = 'default';
				let date: Dayjs | undefined | 'skeleton';
				let tooltip: string | null = null;
				if (isLoadingStatus) {
					variant = 'skeleton';
					date = 'skeleton';
					tooltip = 'Loading status...';
				}

				if (isDone) {
					variant = 'success';
					date = dayjs(row.status.updated_at);
				}
				if (isFailed) {
					variant = 'destructive';
					date = dayjs(row.status.updated_at);
				}
				if (isOutdated) {
					variant = 'colorOrange';
					tooltip = mapOutdatedReasonToCopy(
						row.status.outdated_reason,
					);
					date = dayjs(row.status.updated_at);
				}
				if (isRunning) {
					variant = 'default';
					date = dayjs(row.status.created_at);
				}
				if (neverRan) {
					variant = 'neutral';
					date = undefined;
				}

				return (
					<span className="flex flex-col justify-center p-2">
						<Tooltip content={tooltip} placement="top-start">
							{variant === 'skeleton' ? (
								<Skeleton className="h-5 w-20" />
							) : (
								<span>{badges[variant]}</span>
							)}
						</Tooltip>
						<span className="pt-[2px] text-xxs text-ca-gray-500">
							{date === 'skeleton' && (
								<Skeleton className="h-2 w-8" />
							)}
							{date !== undefined && date !== 'skeleton' && (
								<TimeSince time={date} />
							)}
						</span>
					</span>
				);
			}

			case 'run_action': {
				const overwriteCount =
					(overwriteCounts ?? []).find(
						(o) => o.strategy_id === row.id,
					)?.count ?? 0;

				return (
					<RunButton
						strategyName={row.name}
						overwriteCount={overwriteCount}
						startRunMutation={() => {
							return handleRunClick(row.id);
						}}
						isProcessingMutation={isProcessingMutation}
						isDisabled={isRunning || noPhasesWarning !== undefined}
						disabledMessage={
							isRunning
								? 'Strategy is already running'
								: noPhasesWarning
						}
					/>
				);
			}

			default: {
				return (
					<span className="text-sm text-black">
						{isValidKey(columnId, row) ? String(row[columnId]) : ''}
					</span>
				);
			}
		}
	};
};

export const StrategiesTable = ({
	strategies: listStrategies,
	phases,
	defaultScenarios,
	newStrategyDefault,
	refetchStrategies,
	isLoading,
}: StrategiesTableProps) => {
	const { open } = useModal();
	const queryClient = useQueryClient();
	const strategiesWithStatus = useStrategiesWithStatus(listStrategies);
	const allStatusesQueryKey = useAllLatestRunStatusesQueryKey();
	const { show: showToast } = useToast.getState();

	const strategyWarnings = getStrategyWarnings(listStrategies);
	const phasesWarnings = getPhasesWarnings({
		phases: transformPhases(phases),
		expectEndOfSeason: false,
	});

	const { data: overwritesData } = useChannelQuery<
		OverwriteCountsDataType,
		Error,
		OverwriteCountsDataType
	>(GET_OVERWRITE_COUNTS_QUERY_KEY, GET_OVERWRITE_COUNTS, {
		refetchInterval: 300_000,
		refetchIntervalInBackground: true,
	});

	/* Strategies actions */
	const { mutateAsync: runStrategy, isLoading: isProcessingMutation } =
		useMutation(RUN_STRATEGY, {
			onMutate: async (strategyId) => {
				// Cancel any outgoing refetches (so they don't overwrite our optimistic update)
				await queryClient.cancelQueries(allStatusesQueryKey);

				const previousRunStatuses =
					queryClient.getQueryData(allStatusesQueryKey);
				type AllStatusesQueryData = ReturnType<
					typeof useAllLatestRunStatusesQuery
				>['data'];
				queryClient.setQueryData(
					allStatusesQueryKey,
					(oldData: AllStatusesQueryData) => {
						const copy = { ...oldData };

						if (copy[strategyId] == null) {
							const now = new Date().toISOString();
							copy[strategyId] = {
								id: 'fake',
								created_at: now,
								outdated_reason: null,
								simplified_status: 'RUNNING',
								updated_at: now,
								status: 'STARTED',
								strategy_id: strategyId,
							};
						} else {
							copy[strategyId]!.status = 'STARTED';
							copy[strategyId]!.simplified_status = 'RUNNING';
							copy[strategyId]!.updated_at =
								new Date().toISOString();
						}

						return copy;
					},
				);
				// Return a context object with the snapshotted value
				return {
					optimisticQueryKey: allStatusesQueryKey,
					optimisticRollBackData: previousRunStatuses,
				};
			},
			onError: (err, strategyId, context) => {
				console.log('RUN_STRATEGY mutation err', err);
				if (context === undefined) {
					return;
				}

				queryClient.setQueryData(
					context.optimisticQueryKey,
					context.optimisticRollBackData,
				);
			},
			onSuccess: (_strategyId, _variables, context) => {
				if (context === undefined) {
					return;
				}

				queryClient.invalidateQueries(context.optimisticQueryKey);
			},
		});

	const { mutate: copyStrategy } = useMutation(CREATE_STRATEGY, {
		onMutate: async () => {
			// TODO do we need to cancel anything here?
		},
		onError: (err) => {
			console.log('COPY_STRATEGY mutation err', err);
		},
		onSuccess: async () => {
			await Promise.all([refetchStrategies()]);
			showToast(`Success`, {
				type: 'success',
			});
		},
	});

	const { mutate: deleteStrategy } = useMutation(DELETE_STRATEGY, {
		onMutate: async () => {
			// TODO do we need to cancel anything here?
		},
		onError: (err) => {
			console.log('DELETE_STRATEGY mutation err', err);
		},
		onSuccess: async () => {
			await Promise.all([refetchStrategies()]);
			showToast(`Success`, {
				type: 'success',
			});
		},
	});

	const updateOnSuccess = async () => {
		await Promise.all([refetchStrategies()]);
		showToast(`Success`, {
			type: 'success',
		});
	};

	const handleEditClick = (strategy: Strategy) => {
		console.log('edit strategy', strategy.id);
		open({
			modalNode: (
				<StrategyModal
					type="update"
					strategy={strategy}
					phases={phases}
					defaultScenarios={defaultScenarios}
					newStrategyDefault={newStrategyDefault}
					onSuccess={updateOnSuccess}
				/>
			),
			mode: 'priority',
		});
	};

	// same as onUpdate but it may change in the future
	const createOnSuccess = async () => {
		await Promise.all([refetchStrategies()]);
		showToast(`Success`, {
			type: 'success',
		});
	};

	const handleAddStrategy = () => {
		const tempId = uuidv4();
		open({
			modalNode: (
				<StrategyModal
					type="create"
					strategy={{ name: '', id: tempId, slug: tempId, order: -1 }}
					phases={phases}
					defaultScenarios={defaultScenarios}
					newStrategyDefault={newStrategyDefault}
					onSuccess={createOnSuccess}
				/>
			),
			mode: 'priority',
		});
	};

	const handleRunClick = async (strategyId: string) => {
		return runStrategy(strategyId);
	};

	const handleCopyClick = ({
		strategyId,
		name,
	}: {
		strategyId: string;
		name: string;
	}) => {
		copyStrategy({ name, from_strategy: strategyId });
	};

	const handleDeleteClick = (strategyId: string) => {
		deleteStrategy(strategyId);
	};

	const actionTooltip =
		phasesWarnings.warnings.noScheduledPhases ??
		strategyWarnings.warnings.maxStartegies ??
		undefined;

	return (
		<div>
			<div className="flex items-center justify-between gap-4 py-10">
				<Text type="secondary">
					Each strategy will recommend the most optimal markdown per
					product based on the chosen objective and the applied
					business rules.
				</Text>
				<Tooltip content={actionTooltip} placement="right">
					<span className="relative inline-flex">
						<Button
							size="page-cta"
							className="flex w-[168px] max-w-[168px] flex-row items-center justify-center gap-2 font-normal"
							onClick={handleAddStrategy}
							variant="primary"
							disabled={
								!!phasesWarnings.warnings.noScheduledPhases ||
								!!strategyWarnings.warnings.maxStartegies
							}
						>
							<PlusIcon className="h-2.5 w-auto" />
							Add strategy
						</Button>
					</span>
				</Tooltip>
			</div>
			<Table
				loading={isLoading}
				itemsLoading={4}
				headings={HEADINGS as any}
				rows={strategiesWithStatus}
				emptyState="No strategies exist. Create one with the button above."
				// @ts-ignore
				renderCell={getCellRenderer({
					handleRunClick,
					handleEditClick,
					handleCopyClick,
					handleDeleteClick,
					isProcessingMutation,
					overwriteCounts: overwritesData,
					noPhasesWarning: phasesWarnings.warnings.noScheduledPhases,
					maxStrategiesWarning:
						strategyWarnings.warnings.maxStartegies,
				})}
				className="text-center"
			/>
		</div>
	);
};
