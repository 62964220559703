import { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import {
	Control,
	FieldErrors,
	UseFormSetValue,
	UseFormWatch,
} from 'react-hook-form';

import Condition, {
	ConditionErrors,
	ConditionForm,
	Field,
} from 'components/Condition/Condition';
import { LoadingSpinner } from 'crunch-components';
import { GET_PRODUCT_COUNT } from 'api/products';

import { validateConditions } from 'components/Condition/Condition.utils';

import useChannelQuery from 'hooks/channels/useChannelQuery';

import { components } from '../../../../types/backend-api';
import { FormValues } from '../types/seasons';

type SeasonSettingsScopeProps = {
	fields: Field[];
	isSuccess: boolean;
	setValue: UseFormSetValue<FormValues>;
	control: Control<FormValues, any>;
	watch: UseFormWatch<FormValues>;
	errors: FieldErrors<FormValues>;
};

const SeasonSettingsScope = ({
	fields,
	isSuccess,
	setValue,
	control,
	watch,
	errors,
}: SeasonSettingsScopeProps) => {
	const watchedConditions = watch('conditions');
	const fullConditions = cloneDeep(watchedConditions).filter((c) => validateConditions([c])) as Array<components['schemas']['Condition']>;
	const [debouncedConditions, setDebouncedConditions] = useState<Array<components['schemas']['Condition']>>([]);

	useEffect(() => {
		const timeoutId = setTimeout(() => {
			setDebouncedConditions(fullConditions);
		}, 1500);
		return () => clearTimeout(timeoutId);
	}, [JSON.stringify(fullConditions)]);

	const { isLoading: productCountLoading, data: productCount } = useChannelQuery<number, unknown, number>(
		['scoped-product-count', debouncedConditions],
		() => GET_PRODUCT_COUNT(debouncedConditions),
		{
			staleTime: 30 * 60 * 1000,
		},
	);

	return (
		<div>
			{!isSuccess && <LoadingSpinner variant="md" />}
			{isSuccess && (
				<>
					<Condition
						errors={errors as ConditionErrors}
						fields={fields}
						control={control as unknown as Control}
						setValue={
							setValue as unknown as UseFormSetValue<ConditionForm>
						}
						watch={watch as unknown as UseFormWatch<ConditionForm>}
					/>
					<p className="text-xs text-ca-black">
						{productCountLoading
							? <LoadingSpinner as='span' variant='sm' />
							: (
								<strong>
									{`${productCount?.toLocaleString('en-US')}`}
								</strong>
							)
						}
						&nbsp;products match the selected filters
					</p>
				</>
			)}
		</div>
	);
};

export default SeasonSettingsScope;
