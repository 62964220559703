import { jsx as _jsx } from "react/jsx-runtime";
import * as SwitchPrimitive from '@radix-ui/react-switch';
import cn from '../../utils/cn';
const Switch = ({ checked, onChange, disabled = false, }) => {
    const handleCheckedChange = (checked) => {
        if (onChange) {
            onChange(checked);
        }
    };
    return (_jsx(SwitchPrimitive.Root, { className: cn('relative inline-flex items-center justify-between w-10 h-6 rounded-full transition-colors', checked ? 'bg-mi-velvet-lilac' : 'bg-gray-400', disabled ? 'opacity-50 cursor-not-allowed' : ''), checked: checked, onCheckedChange: handleCheckedChange, disabled: disabled, children: _jsx(SwitchPrimitive.Thumb, { className: cn('block w-5 h-5 bg-white rounded-full transition-transform transform', checked ? 'translate-x-4' : 'translate-x-1') }) }));
};
export default Switch;
