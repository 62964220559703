import { Checkbox } from 'crunch-components';
import { Control, Controller } from 'react-hook-form';
import { FormValues } from '../types/seasons';

type SeasonSettingsCostProps = {
	control: Control<FormValues>;
};

const SeasonSettingsCost = ({ control }: SeasonSettingsCostProps) => {
	return (
		<div className="flex w-max flex-col items-stretch gap-y-3">
			<Controller
				name="includeShippingCost"
				control={control}
				render={({ field: { value, onChange } }) => (
					<Checkbox
						swapPosition
						checked={value}
						onChange={onChange}
						label="Include shipping cost"
					/>
				)}
			/>
			<Controller
				name="includeReturnCost"
				control={control}
				render={({ field: { value, onChange } }) => (
					<Checkbox
						swapPosition
						checked={value}
						onChange={onChange}
						label="Include return cost"
					/>
				)}
			/>
		</div>
	);
};

export default SeasonSettingsCost;
