import Title from 'components/Title/Title';

const FeatureFlagsHeader = () => (
	<div className="flex justify-between">
		<div className="flex flex-col gap-2">
			<Title size="h1-like" font="serif" color="forest" as="h1">
				Feature Flags Management
			</Title>
		</div>
	</div>
);

export default FeatureFlagsHeader;
