import { components } from '../../../types/backend-api';
import { ConditionType, Field } from './Condition';

export function filtersToFields(
	rawFields: components['schemas']['UIFilterDefinition'][] | undefined,
): Field[] {
	if (rawFields == null) {
		return [];
	}

	// Sort mutates the array in place. We don't want to mutate the original array.
	return [...rawFields]
		.sort((a, b) => {
			return a.name.localeCompare(b.name);
		})
		.map((field) => {
			const baseField = {
				value: field.id,
				label: field.name,
				type: field.type,
				options: [],
			};

			if (field.type === 'Checkbox') {
				if (field.checklist_options == null) {
					throw new Error(
						'Filter parsing error: no checklist options on checkbox filter.',
					);
				}

				return {
					...baseField,
					options: field.checklist_options.map((o) => ({
						value: o,
						label: o,
					})),
				};
			}

			if (field.type === 'List') {
				if (field.list_options == null) {
					throw new Error(
						'Filter parsing error: no list options on list filter.',
					);
				}

				return {
					...baseField,
					options: field.list_options.map((o) => ({
						value: o,
						label: o,
					})),
				};
			}

			return baseField;
		});
}

export function validateConditions(conditions: ConditionType[]): boolean {
	return conditions.every((condition) => {
		return (
			condition.field !== null &&
			condition.operator !== null &&
			condition.value !== null &&
			condition.value !== ''
		);
	});
}
