import { StratosLogOutButton } from 'components/StratosSidebar/StratosSidebar';
import { Logo, Page, Text } from 'crunch-components';

export default function NoAccess() {
	return (
		<Page className='flex py-8 px-10 w-full min-h-screen'>
			<Logo className='absolute h-[48px] w-[48px]' />
			<div className='flex justify-center items-center w-full'>
				<div className='flex flex-col items-center gap-6'>
					<img
						alt='No access'
						src={process.env.PUBLIC_URL + '/facepalm_tshirt.png'}
						width='480'
						height='360'
						className='mb-10 rounded-lg'
					/>
					<Text>
						<span>
							You have not been assigned access to any channels.
						</span>
						<a href='mailto:support@markmi.ai' className='underline'>
							Please contact support@markmi.ai.
						</a>
					</Text>
					<Text>
						<span>
							You may need to log out for changes to take effect.
						</span>
					</Text>
					<StratosLogOutButton />
				</div>
			</div>
		</Page>
	);
}
