import { jsx as _jsx } from "react/jsx-runtime";
import cn from '../../utils/cn';
import Tooltip from '../Tooltip/Tooltip';
const IconButton = ({ icon: Icon, tooltip = '', cursor = '', className = 'h-5', disabled = false, onClick = () => { } }) => {
    if (!Icon) {
        return null;
    }
    return (_jsx(Tooltip, { content: tooltip, placement: "top-start", children: _jsx("span", { className: "inline-flex", children: _jsx("button", { type: "button", disabled: disabled, onClick: onClick, className: cn('p-2 text-ca-gray transition-colors focus:outline-none focus-visible:ring-4 focus-visible:ring-opacity-10 focus-visible:ring-ca-purple focus-visible:text-ca-gray-a', !disabled && 'hover:text-ca-gray-a active:text-ca-gray-a', disabled && 'cursor-not-allowed text-ca-silver', cursor && cursor), children: _jsx(Icon, { className: className }) }) }) }));
};
export default IconButton;
