import { useQuery } from 'react-query';

import { ALL_FEATURE_FLAGS, PATCH_FEATURE_FLAG } from 'api/feature-flags';
import {
	TableV2,
	Tooltip,
	FlaskIcon,
	InfoIcon,
	Switch,
} from 'crunch-components';
import { FeatureFlagObject } from '../types/feature-flags';

const HEADING_ROWS = [
	{
		id: 'id'
	},
	{
		id: 'ff_id',
		label: 'Id',
	},
	{
		id: 'enabled',
		label: 'Enabled',
	},
];

const renderCell = (
	row: FeatureFlagObject,
	columnId: string,
	refetchFeatureFlags: () => void,
) => {
	const {
		id,
		description,
		experimental,
		enabled: isEnabled
	} = row;
	switch (columnId) {
		case 'id':
			return (
				experimental && (
					<Tooltip content='Experimental'>
						<span>
							<FlaskIcon className="ml-1 mt-0.5 h-4 text-gray-400" />
						</span>
					</Tooltip>
				)
			);

		case 'ff_id':
			return (
				<span className='inline-flex gap-2 items-center'>
					{id}
					<Tooltip content={description}>
						<span>
							<InfoIcon className="h-4 text-gray-400" />
						</span>
					</Tooltip>
				</span>
			)

		case 'enabled':
			return (
				<Switch
					checked={isEnabled}
					onChange={async (e) => {
						await PATCH_FEATURE_FLAG(
							id,
							{
								enabled: !isEnabled
							}
						);
						refetchFeatureFlags();
					}}
				/>
			);

		default:
			return null;
	}
};

const FeatureFlagsTable = () => {
	const {
		isLoading: featureFlagLoading,
		data: featureFlagData,
		refetch: refetchFeatureFlags
	} = useQuery(
		['feature-flags-fetch'],
		ALL_FEATURE_FLAGS,
		{ staleTime: 5 * 60 * 1000 }
	);

	return (
		<TableV2
			loading={featureFlagLoading}
			rows={featureFlagData ?? []}
			renderCell={(r, c) => renderCell(r, c, refetchFeatureFlags)}
			headings={HEADING_ROWS}
		/>
	);
};

export default FeatureFlagsTable;
